.wrap {
    @include outer-container(1200px);
    padding: 3rem 1rem;
    ul {
        padding-left: 0;
    }

    h3 {
        @extend %no-bottom-margin;
        font-family: $helvetica;
        color: palette(aap-black, mid-dark);
    }
    p {
        @extend %no-top-margin;
    }
    .button {
        width: 20rem;
    }
}
.blurb {
    text-align: center;

}
.wrapper {
    background: url('../img/home/rehome_hero.jpg') no-repeat center top;
    background-size: contain;
}
.pitch {
    text-align: center;
    padding-top: 41%;

    .full {
        background-color: palette(aap-white, x-light);
    }

    h4 {
        font-size: 1.2rem;
    }
    ul {
        @include outer-container(990px);
        margin-top: 2rem;
        li {
            @include span-columns(4);
            @include media($tablet) {
                @include span-columns(12);
                padding-right: 5%;
                padding-left: 5%;
                padding-top: 1%;
                padding-bottom: 1%;
            }
            &:nth-child(even) {
                @include media($tablet) {
                    background-color: palette(aap-white, x-light);
                }
            }
        }
        span {
            display: inline-block;
            width: 6rem;
            height: 6rem;
            padding: 1.5rem;
            background: palette(aap-orange, light);
            border-radius: 50%;
        }
        p {
            margin: 1.8rem 0;
        }
        svg {
            fill: palette(aap-white, x-light);
            width: 3rem;
            height: 3rem;
        }
    }
}
.how-it-works {
    background: palette(aap-blue, dark);
    position: relative;

    .button {
        color: palette(aap-blue, dark);
    }
    .mobile-number {
        display: none;
        @include media($tablet) {
            display: block;
            position: absolute;
            top: -1.5em;
            left: 50%;
            transform: translateX(-50%);
        }

        svg {
            margin: 0;
            padding: 0;
            width: 7em;
            height: 7em;
        }
    }

    h1,
    h3 {
        color: palette(aap-white, x-light);
    }
    h1,
    h3 {
        text-align: center;
    }
    .svg {
        text-align: center;
        padding: 2% 0 7%;

        @include media($tablet) {
            display: none;
        }
        img {
            width: 60%;
            height: 60%;
        }
    }
    p {
        text-align: center;
        &.learn {
            margin-top: 2em;
        }
        .number {
            display: block;
            width: 3em;
            line-height: 3em;
            margin: 0 auto;
            background: palette(aap-white, x-light);
            color: palette(aap-blue, dark);
            border-radius: 50%;
            position: relative;
            &::before {
                content: '';
                width: 6em;
                overflow: hidden;
                color: palette(aap-white, x-light);
                font-size: 2em;
                position: absolute;
                right: 1em;
                top: 40%;
                letter-spacing: .4em;
                border: solid 5px;
            }
            &::after {
                content: '';
                width: 6em;
                overflow: hidden;
                color: palette(aap-white, x-light);
                font-size: 2em;
                position: absolute;
                left: 1em;
                top: 40%;
                letter-spacing: .4em;
                border: solid 5px;
            }
        }
    }
}
.good-hands {
    h1 {
        text-align: center;
    }
    ul {
        @include outer-container(768px);
    }
    li {
        list-style: none;
        margin-bottom: 3%;
        @include span-columns(6);
        @include omega(2n);
        @include media($phone) {
            @include span-columns(12);
            text-align: center;
            margin-right: auto;
            margin-left: auto;
        }
        h5 {
            text-transform: uppercase;
        }
        h5,
        p {
            margin-left: 3rem;
            margin-right: 3rem;
            font-size: 1.2em;
            margin-bottom: 2%;
        }
    }
}
.request-help,
.here-to-help {
    text-align: center;
    P {
        margin-top: 3em;
    }
}
.request-help {
    background-color: palette(aap-white, light);

}

.home {
    p {
        font-weight: 200;
    }
}


.home-b {
    .wrapper {
        background-image: none;
    }
    .pitch {
        padding-top: 0;

        ul {
            li {
                &:nth-child(even)  {
                    background: transparent;
                }
            }
        }
        .full {
            background-color: palette(aap-white, light);
        }
    }
    .create-profile {
        margin-top: 5em;
    }
}
