.payments {
    .form-control {
        &.three {
            div {
                @include span-columns(4);
            }
        }
    }
    .here-to-help {
        text-align: left;
        .wrap {
            padding: 2rem 0;
        }
        .button {
            text-align: center;
        }
    }
}
