.staff {
    padding: 1em 2em;
    @include outer-container;

    input {
        margin-bottom: 0;
    }

    a {
        color: palette(aap-blue, dark);

        &:hover {
            text-decoration: none;
        }
    }

    td {
        form {
            float: left;
        }
    }

    .images {
        @include outer-container(100%);
    }

    .image {
        @include span-columns(3);

        img {
            width: 100%;
            height: auto;
        }
    }

    .vert {
        th {
            text-align: left;
            padding-right: $spacing;
            vertical-align: text-top;
            width: 30%;
        }


        td {
            text-transform: capitalize;
            vertical-align: text-top;
        }
    }

    .publish {
        margin: $spacing 0;
        text-align: right;
    }

    .debug {
        margin: 40px 0;
    }
}

ul.button-menu {
    display: none;

    li {
        list-style: none;
        line-height: 1.5em;
        font-size: 1.3em;
        cursor: pointer;
        bottom-border: 1px solid palette(aap-black, x-light);
    }

    li:last-of-type {
        bottom-border: none;
    }

    &.active {
        display: block;
    }
}

button.hide-pet {
    font-size: 1.7em !important;
}

.flash-alert {
    @include flash($alert-color);
}
.flash-error {
    @include flash($error-color);
}
.flash-notice {
    @include flash($notice-color);
}
.flash-success {
    @include flash($success-color);
}

div.pending-review-dashboard {
    @include span-columns(12);


    div.pet-list {
        @include span-columns(12 of 12);
    }

    div.pet-review-modal {
        @include span-columns(12 of 12);
        background: #fff;
        height: 100%;
        z-index: 99999;
        position: fixed;
        overflow-y: scroll;
        top: 0;
        left: 0;
        &.closed {
            display: none;
        }
    }

    div.clear {
        @include clearfix;
    }

    table {
        tr {
            th {
                white-space: nowrap;
            }
        }
    }

    input.flag-all {
        color: #fff;
        background-color: red;
        margin-right: 30px;
    }

    input.approve-all {
        color: #fff;
        background-color: palette(aap-green, light);
    }

    input.review-pet {
        color: #fff;
        background-color: palette(aap-blue, blue);
            margin-bottom: 22px;
    }

    input.flag-pet {
        color: #fff;
        background-color: palette(aap-red, mid-light);
        margin-bottom: 22px;
    }

    input.approve-pet {
        color: #fff;
        background-color: palette(aap-green, light);
    }

    button.close {
        float: right;
    }
}
