@keyframes loading {
    0%,
    100% {
        transform: translate(0, 0);
    }

    50% {
        width: 25px;

        transform: translate(180px, 0);
    }
}
