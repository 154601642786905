section.redesign {
	@include outer-container;

	@include media($tablet-plus) {
		padding-left: 3%;
		padding-right: 3%;
	}

	h3 {
		-webkit-margin-after: 0;
		-webkit-margin-before: 0;
		font-size: 26px;
		line-height: 1.5;
		font-family: $primaryFont;
		color: palette(aap-mono, dark);

		&.bold {
			font-weight: bold;
			font-style: normal;
			font-stretch: normal;
			letter-spacing: -1px;

			&.thanks-subheader {
				@include span-columns(8.5 of 12);
				@include shift(1.75);

				@include media($phablet) {
					@include span-columns(4 of 4);
					@include shift(0);
				}
			}
		}

		&.thin {
			font-weight: 100;
			font-style: normal;
			font-stretch: normal;
			line-height: 0.77;
			letter-spacing: normal;
		}
	}

	h4 {
		-webkit-margin-after: 0;
		-webkit-margin-before: 0;
		font-size: 20px;
		font-style: normal;
		font-stretch: normal;
		line-height: 1.6;
		font-family: $primaryFont;
		letter-spacing: 0.77px;

		&.bold {
			font-weight: bold;
		}

		&.thin {
			font-weight: 200;
		}

		&.light {
			font-weight: 300;
		}

		&.medium {
			font-weight: 500;
			line-height: 1.3;
			letter-spacing: normal;
		}
	}

	form {
		input[type="checkbox"] {
			display: none;
		}

		input[type="checkbox"] + label {
			background: url('/img/v2/onboarding/check_1.svg') no-repeat;
			//background-size: 100%;
			height: 21px;
			width: 21px;
			padding: 0 0 0 38px;
			margin-top: 5px;
		}

		input[type="checkbox"]:checked + label {
			background: url('/img/v2/onboarding/check_2.svg') no-repeat;
			height: 21px;
			//background-size: 100%;
			width: 21px;
			padding: 0 0 0 38px;
			margin-top: 5px;
		}
		div.clear {
			@include clearfix;
		}
		div.inline-form-field {
			div.more {
				position: relative;
				&.error {
					input {
						border: 1px solid palette(aap-orange, orange) !important;
					}

					textarea {
						border: 1px solid palette(aap-orange, orange) !important;
					}
				}
				input {
					border: 1px solid #b3b3b3 !important;
				}
				display: block;
				&.closed {
					display: none;
				}
				span.error {
				    position: absolute;
				    top: 50px;
				    right: 4px;
				    @include media($phablet) {
				    	right: 4px;
				    }
				    &.for-textarea {
				    	position: absolute;
				    	top: 80px;
						right: -30px;
						@include media($phablet) {
							right: 4px;
						}
				    }
				}
			}
		}
		div.field-group {
			@include span-columns(12 of 12);
			@include media($tablet) {
				@include span-columns(12);
			}

			@include media($phablet) {
				@include span-columns(4);
				padding-bottom: 40px;
				margin-top: 40px;
			}
			&.submit-button {
				border: none;
				margin-top: 80px;
				margin-bottom: 0px;
				padding-bottom: 0px;
				@include media($phablet) {
					margin-top:60px;
				}
				div.form-field {
					margin-top: 0px;
					margin-bottom: 0px;

					input.submit {
						margin-top: 0px;
					}
				}
			}

			&.with-textarea {
				height: 280px;
				textarea {
					font-size: 20px;
				}

				@include media($phablet) {
					height: 370px;
				}
			}

			&.petco-stores {
				h3.field-group-header {
					@include span-columns(4 of 12);
					@include media($tablet) {
						@include span-columns(12 of 12);
						margin-bottom: 40px;
					}

					@include media($phone) {
						@include span-columns(4 of 4);
					}
				}
				span.error-message {
						font-size: 20px;
						font-weight: bold;
						font-style: normal;
						font-stretch: normal;
						line-height: normal;
						letter-spacing: normal;
						color: #f5a623;
						margin-bottom: 40px;
						float: left;
						&.hidden {
							visibility: hidden;
						}
				}

				table {
					th {
						background-color: rgba(215, 215, 215, 0.1);
						padding-top: 14px;
						padding-bottom: 20px;
						text-align: left;
						padding-left: 16px;
					}
					tr {
						background-color: #fff;
						border-left: none;
						border-right: none;

					}

					td {
						border-left: none;
					}
				}

				h4 {
    				margin-bottom: 20px;
				}
			}
			input.submit {
				@include span-columns(4 of 12);
				@include shift(4);
				color: palette(aap-mono, lightest);
				font-size: 20px;
				font-style: normal;
				font-stretch: normal;
				font-weight: bold;
				line-height: 1.25;
				margin-top: 30px;
				margin-bottom: 80px;

				@include media($tablet) {
					@include span-columns(12 of 12);
					@include shift(0);
					margin-top: 20px;
				}

				@include media($phone) {
					@include span-columns(4 of 4);
					@include shift(0);
					margin-top: 20px;
				}

				&.blue {
					padding: 15px 83px 18px 82px;
					border-radius: 6px;
					background-color: palette(aap-blue, blue);
					&:hover {
						box-shadow: inset 0 -3px 0px 0px rgba(0, 0, 0, 0.4);
						background-color: palette(aap-blue, dark);
	                    transition: all 0.3s ease 0s;
					    &:active {
					    	box-shadow: inset 0 3px 4px 0px rgba(0, 0, 0, 0.4);
					    	background-color: palette(aap-blue, blue);
					    }
					}

					&:disabled {
						background-color: palette(aap-mono, light);
						border: 1px solid palette(aap-mono, light);
						border-radius: 6px;
						cursor: not-allowed;
						&:hover {
							box-shadow: none;
							&:active {
								box-shadow: none;
							}
						}
					}
				}
			}
			h3.field-group-header {
				@include span-columns(5.7 of 12);
				line-height: 1.15;

				&.short {
					@include span-columns(2 of 12);

					@include media($tablet) {
						@include span-columns(4 of 12);
					}

					@include media($phone) {
						@include span-columns(4 of 4);
					}
				}

				@include media($tablet) {
					@include span-columns(12 of 12);
					margin-bottom: 40px;
					&.terms-header {
						margin-bottom: 0px;
					}
				}

				@include media($phone) {
					@include span-columns(4 of 4);
				}
			}
			padding-bottom: 60px;
			margin-top: 60px;
			border-bottom: solid 2px palette(aap-mono, light);
			h4.field-group-subheader-side {
				color: palette(aap-mono, light-grey);
				line-height: 1.6;
			}

			h4.field-group-subheader {
				@include span-columns(9 of 12);
				margin-top: 30px;
				color: palette(aap-mono, light-grey);
				line-height:1.6;
				letter-spacing:0;

				@include media($tablet) {
					@include span-columns(12 of 12);
				}

				@include media($phone) {
					@include span-columns(4 of 4);
				}

				&.top {
					margin-top: 0px;
					margin-bottom: 60px;
				}

				a {
					font-weight: bold;
					letter-spacing: -0.5px;
					color: palette(aap-blue, blue);
					text-decoration: none;
					cursor: pointer;

					&:hover {
						text-decoration: underline;
						color: palette(aap-blue, dark);
	                    transition: all 0.3s ease 0s;

						&:active {
							text-decoration: underline;
							color: palette(aap-blue, darkest);
						}
					}
				}

				@include media($tablet) {

				}

				@include media($phone) {

				}
			}
			div.clear {
				@include clearfix;	
			}
			div.inline-form-field {
				@include span-columns(6 of 12);
				@include media($tablet) {
					@include span-columns(8 of 12);
					@include shift(0);
				}

				@include media($phablet) {
					@include span-columns(4);
					@include shift(0);
				}
				background-color: palette(aap-mono, lightest);
				border: 1px solid palette(aap-mono, mid-light);
				border-radius: 6px;
				height: 58px;
				margin-bottom: 20px;

				&.active {
					border: solid 1px palette(aap-blue, blue);
					border-radius: 6px;
				}
				div.clear {
					@include clearfix;	
				}
				&.error {
					border: solid 1px palette(aap-orange, orange);
					border-radius: 6px;

					div.field {
						@include span-columns(3 of 6);
						@include media($tablet) {
							@include span-columns(4 of 8);
						}

						@include media($phablet) {
							@include span-columns(2.2 of 4);
							//@include omega();
						}
						select {
							background: url(/img/v2/forms/error-dropdown.svg);
					    	background-repeat: no-repeat;
					    	background-position: right 10px center;
					    	background-size: 15px 10px;
							outline: none;
							font-weight: 300;
							-moz-appearance: none;
							-webkit-appearance: none;
							appearance: none;
							&::-ms-expand { display: none };
						}
						input {
							@include span-columns(3 of 3);
							height: 26px;
							border: none;
							color: palette(aap-mono, dark);
							font-size: 20px;
							font-weight: 300;
		    				line-height: 1.3;
						    outline: none;
							-moz-appearance: none;
							-webkit-appearance: none;
							appearance: none;
							padding-left: 11px;
							height: 56px;
							vertical-align: middle;
							padding-top: 0px;
							padding-bottom: 0px;

							&.show {
								height: 26px;
							}					
							@include media($tablet) {
								@include span-columns(4 of 4);
								@include shift(0);
								//margin-top: 0px;
							}

							@include media($phablet) {
								@include span-columns(2 of 2);
								@include shift(0);
								//margin-top: 0px;
								font-size: 16px;
							}

							&:focus {
							    outline: none;
								-moz-appearance: none;
								-webkit-appearance: none;
								appearance: none;
							}
						}
					}
				}
				
				div.field {
					@include span-columns(6 of 6);
					@include media($tablet) {
						@include span-columns(8 of 8);
					}

					@include media($phablet) {
						@include span-columns(4 of 4);
					}

					label {
						color: palette(aap-mono, lightest);
						height: 20px;
						line-height: 1.57;
	    				font-size: 14px;
	    				padding-left: 11px;
	    				display: none;
	    				// override form.scss settings
	    				margin-top: 0 !important;
	    				margin-bottom: 0 !important;
	    				text-transform: none !important;
	    				font-weight: normal !important;

						&.show {
							color: palette(aap-mono, mid-light);
							display: block;
							padding-top: 5px;
						}
					}

					input {
						@include span-columns(6 of 6);
						border: none;
						color: palette(aap-mono, dark);
						font-size: 20px;
						border-radius: 6px;
	    				line-height: 1.3;
					    outline: none;
						-moz-appearance: none;
						-webkit-appearance: none;
						appearance: none;
						padding-left: 11px;
						height: 56px;
						vertical-align: middle;
						font-weight: 300;
						box-shadow: none !important;
						-webkit-transition: none !important;
						transition: none !important;
						padding-top: 0px;
						padding-bottom: 0px;

						&.show {
							height: 26px;
						}
						
						@include media($tablet) {
							@include span-columns(8 of 8);
							@include shift(0);
							//margin-top: 0px;
						}

						@include media($phablet) {
							@include span-columns(4 of 4);
							@include shift(0);
							//margin-top: 0px;
							font-size: 16px;
						}

						&:focus {
						    outline: none;
							-moz-appearance: none;
							-webkit-appearance: none;
							appearance: none;
						}
					}

					&.has-select {
						@include span-columns(6 of 6);
						@include media($tablet) {
							@include span-columns(6 of 6);
						}

						@include media($phone) {
							@include span-columns(4 of 4);
						}
					}

					select {
						@include span-columns(6 of 6);
						border: none;
						color: palette(aap-mono, dark);
						font-size: 20px;
	    				line-height: 1.3;
	    				font-weight: 300;
	    				padding-left: 11px;
	    				background-color: palette(aap-mono, lightest);
					    background-image: url(/img/v2/forms/dropdown.svg);
					    background-repeat: no-repeat;
					    background-size: 15px 10px;
					    background-position: right 10px center;
					    outline: none;
						-moz-appearance: none;
						-webkit-appearance: none;
						appearance: none;
						&::-ms-expand { display: none };
						height: 56px;
						vertical-align: middle;
						box-shadow: none !important;

	
						&.show {
							height: 26px;
							padding: 0 !important;
							padding-left: 11px !important;
							margin-bottom: 24px;
							padding-top: 0px !important;
							padding-bottom: 0px !important;
						}
						&.unset {
							color: palette(aap-mono, mid-light);
						}

						@include media($tablet) {
							@include span-columns(6 of 6);
							@include shift(0);
							margin-top: 0px;
						}

						@include media($phone) {
							@include span-columns(4 of 4);
							@include shift(0);
							margin-top: 0px;
							font-size: 16px;
						}

						&:focus {
							outline: none;
						}						
					}
				}

				span.error {
					@include span-columns(3 of 6);
					font-size: 12px;
					font-style: normal;
					font-stretch: normal;
					line-height: normal;
					letter-spacing: normal;
					font-weight: 500;
					color: palette(aap-orange, orange);
					text-align: right;
					padding-top: 21px;
					padding-right: 18px;
					&.long {
						text-align: left;
						padding-top: 11px;
					}
					@include media($tablet) {
						@include span-columns(4 of 8);
						//@include shift(0);
						font-size: 12px;
						right: 20px;
						//margin-top: 20px;
						&.in-more {
							right: 4px;
						}
						&.long {
							@include span-columns(2 of 4);
							padding-top: 7px;
							right: 3px;
						}
					}

					@include media($phablet) {
						@include span-columns(3 of 4);
						//@include shift(0);
						font-size: 12px;
						@include omega();
						position: absolute;
						right: 38px;
						//margin-top: 20px;
						&.in-more {
							right: 4px;
						}
						&.long {
							@include span-columns(2.5 of 4);
								    padding-top: 18px;
							    right: 25px;
						}
					}

					@include media($phone) {
						@include span-columns(3 of 4);
						//@include shift(0);
						font-size: 12px;
						@include omega();
						position: absolute;
						right: 20px;
						//margin-top: 20px;
						&.in-more {
							right: 4px;
						}
						&.long {
							@include span-columns(2.5 of 4);
							padding-top: 7px;
							right: 3px;
						}
					}
				}
			}
		}
	}
}