header {
    .favorites {
        position: absolute;
        top: 15px;
        right: 5px;

        margin-right: 29px;

        font-size: 13px;

        @include font('Museo Sans', 500);
        @extend %text-right;
        @include media($tablet) {
            margin-right: 0;
        }
        @include media($phone) {
            font-size: 11px;
        }

        a {
            color: palette(aap-blue, dark);
            svg {
                display: inline-block;

                width: 12px;
                height: 12px;
                margin: 0 5px;

                fill: palette(aap-orange, light);
            }
        }
    }
}
