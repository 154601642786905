.auth,
.contract {
    ul,
    div {
        &.errors {
            display: none;
        }
    }
    .errors {
        padding: $spacing;

        color: $error-color;
        //display: none;
        border: solid 1px $error-color;
        border-radius: $border-radius;

        li {
            margin-left: $spacing;
        }
    }
}
.auth {
    @include outer-container(26.875em);
    @include media($phone) {
        padding: 0 10px;
    }

    strong {
        color: palette(aap-black, x-dark);
    }

    a {
        color: palette(aap-white, dark);

        &:hover {
            text-decoration: none;
        }

        &.save {
            display: block;

            margin-top: 40px;

            text-align: center;

            color: palette(aap-blue, dark);
        }
    }

    textarea {
        &.hidden {
            display: none;
        }

        ~ p {
            position: absolute;
            right: 12px;
            bottom: -12px;

            font-size: .8rem;
        }
    }

    label {
        font-size: .8rem;

        &.label-checkbox {
            font-size: 1rem;
        }
        &.label-radio {
            display: inline-block;
            text-transform: none;
            font-size: 1rem;
        }

        &.normal-label {
            display: inline;

            text-transform: none;

            span {
                cursor: pointer;

                color: palette(aap-blue, dark);
            }
        }

        span {
            color: palette(aap-white, mid-light);

            font-weight: 500;
        }
    }

    .helper {
        position: absolute;
        top: 0;
        right: 0;

        font-size: .8rem;
    }

    span {
        &.warning {
            color: $error-color;
            font-size: 1rem;
        }

        &.error {
            position: absolute;
            bottom: -12px;
            left: 0;

            margin-top: $small-spacing;

            color: $error-color;

            font-size: 1rem;
        }

        &.great {
            position: absolute;
            right: 0;
            bottom: 10px;

            display: none;

            width: 22%;
            padding: $spacing;

            text-align: right;
            text-transform: uppercase;

            font-size: .8rem;
            font-weight: 700;


            &.error-flag {
                display: inline-block;

                color: $error-color;
            }

            &.valid-flag {
                display: inline-block;

                color: palette(aap-green, dark);
            }
        }
    }

    .has-error {
        input,
        select,
        textarea {
            border-color: $error-color;
        }
    }

    .form-control {
        @include outer-container(100%);

        > div {
            position: relative;
        }

        [type=submit],
        button {
            margin-top: 20px;
            margin-bottom: 40px;
        }
    }
    .more,
    .bonded {
        overflow-y: hidden;

        max-height: 100px;

        transition-timing-function: cubic-bezier(0, 1, .5, 1);
        transition-duration: .5s;
        transition-property: max-height;

        &.closed {
            max-height: 0;
        }

        &.floater {
            height: 100%;
            max-height: 100%;
        }

        &.terms {
            overflow-y: scroll;
        }
    }

    .textarea {
        max-height: 140px;
    }
    .bonded {
        max-height: 150px;
    }


    .login-help {
        text-align: center;

        a {
            color: palette(aap-blue, dark);
        }
    }

    .reset-password-button-holder {
        padding: 20px 0 40px;
    }

    &.steps {
        h2 {
            margin-bottom: 1rem;
        }
        label {
            display: none;
        }

        .emph {
            color: palette(aap-black, x-dark);

            font-weight: 500;
        }
    }
    .bitten-anyone {
        display: block;
        &.closed {
            display:none;
        }
    }

    .more-message {
        display: block;
        &.closed {
            display: none;
        }
    }
}


.checkbox-has-more {

    &.more {
        position: fixed;
        z-index: 10;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;

        width: 100%;
        height: 100%;

        transition-timing-function: cubic-bezier(0, 1, .5, 1);
        transition-duration: .5s;
        transition-property: left;

        background: palette(aap-white, light);
        &#shelter_rescue_search_form {
            background: palette(aap-white, x-light);
        }

        &.closed {
            overflow-y: hidden;

            left: -100%;
        }
    }

    textarea {
        resize: none;
        ~ p {
            position: absolute;
            right: 12px;
            bottom: -12px;

            font-size: .8rem;
        }
    }

    > div {
        padding: $spacing;

        &.alpha-filters {
            max-width: 100%;
            width: 100%;
        }

        @include outer-container(26.875em);

        > div {
            position: relative;
        }

    }
}

[type=file] {
    display: none;
}

.uploads {
    form {
        text-align: center;

        @include span-columns(6);
    }

    label {
        cursor: pointer;

        &:hover {
            svg {
                background: palette(aap-blue, dark);
            }
        }
    }

    svg {
        width: 6rem;
        height: 6rem;
        margin-bottom: 20px;

        border-radius: 50%;
        background: palette(aap-white, mid-light);

        fill: palette(aap-white, x-light);
    }
}

.form-control {
    .awesomplete {
        width: 100%;
    }
}

ul {
    &.errors {
        display: none;
    }
}

.easy {

    position: relative;

    .real-uploader {
        position: absolute;
        bottom: 1168px;
        left: 0;
        width: 100%;

        @include media($tablet) {
            bottom: 1168px;
        }

        @media only screen and (max-width: 410px) {
            bottom: 1168px;
        }

        @media only screen and (max-width: 330px) {
            bottom: 1168px;
        }
    }

    .dummy-upload {

        border: $border;
        border-radius: $border-radius;
        padding: $small-spacing;
        padding-bottom: 250px;


    }

    .uploads {

        form {
            width: 100%;
        }
    }

    .bolded {
        font-weight: bold;
    }

    .shaded {
        background: palette(aap-white, light);
        padding-left: $spacing;
    }
}
