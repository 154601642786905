div {
    &.awesomplete {
        > input {
            // get rid of browser default arrow
            &::-webkit-calendar-picker-indicator {
                display: none;
            }
        }

        &.active {

            //&:before {

            //}
        }
    }

}

.awesomplete {
    $highlight: #fff5ee;
    > ul {
        > li {
            &:hover {
                color: palette(aap-white, x-light);
                background: palette(aap-blue, light);
            }

            &[aria-selected='true'] {
                background: palette(aap-blue, light);
            }
        }

        mark {
            background: $highlight;
        }
    }
}
