.has-drag-support {

    .uploading {
        .bar {
            float: left;

            width: 15px;
            height: 6px;

            animation: loading 2s cubic-bezier(.17, .37, .43, .67) infinite;

            border-radius: 2px;
            background-color: palette(aap-blue, dark);
        }
    }
    .uploading,
    .error {
        display: none;
    }


    &.is-uploading {
        .upload {
            .uploading {
                display: block;

                text-align: center;
            }
        }
    }

    &.is-error {
        .upload {
            .error {
                position: static;

                display: block;

                text-align: center;
            }
        }
    }

    &.is-dragover {
        .drop-zone {
            border: 1px dashed palette(aap-white, mid-light);
        }
    }
}

.upload-error {
    display: none;

    &.nouploads {
        display: block;

        text-align: center;

        color: $error-color;
    }
}


