$leading: 1.6;

.intro {
    position: relative;
    text-align: center;
}


.hiw {
    p {
        font-weight: 200;
    }
    .wrapper {
        background: url('../img/how-it-works/hiw_hero.jpg') no-repeat center top;
        background-size: contain;
    }

    .steps {
        display: table;
        width: 100%;
        table-layout: fixed;
        padding-top: 41%;

        .button {
            width: auto;
        }
        .wrap {
            padding: 0;
        }
        .step {
            &:nth-child(even) {
                background: palette(aap-white, light);
            }
        }
        .img {
            display: table-cell;
            width: 40%;
            vertical-align: middle;
            @include media($tablet) {
                display: none;
            }


            div {
                text-align: center;
                padding: 5% 0;
            }
            img {
                width: 65%;
                height: 65%;
            }
        }
        .step-content {
            div {
                text-align: left;

                h2 {
                    @extend %no-top-margin;
                    font-family: $helvetica;
                    font-weight: 300;
                }
                h4 {
                    @extend %no-bottom-margin;
                    text-transform: uppercase;
                }

            }
            display: table-cell;
            width: 60%;
            vertical-align: middle;
            padding: 10% 8% 10% 10%;


            @include media($tablet) {
                display: block;
                width: 100%;
            }

        }
    }
}

.hiw-b {
    line-height: $leading;
    .wrapper {
        background-image: none;
    }

    .steps {
        padding-top: 0;
        .wrap {
            border-bottom: solid 1px palette(aap-white, mid-light);
            padding-bottom: 4em;
            font-size: 1.2rem;
        }
        .step {
            float: left;
            width: 25%;
            text-align: center;
            &:nth-child(even) {
                background: palette(aap-white, x-light);
            }

            &:first-child {
                .icon {
                    &::before {
                        display: none;
                    }
                }
            }
        }
        @include media($tablet) {
            display: none;
        }
    }

    .steps-details {
        .wrap {
            padding-top: 1em;
        }
        p {
            margin-top: .5em;
        }
        .icon {
            display: none;
            &::before {
                display: none;
            }

            @include media($tablet) {
                display: block;
            }
        }
        .step {
            padding: 4em;
            border-bottom: solid 1px palette(aap-white, mid-light);
            ul {
                color: palette(aap-white, mid-dark);
                margin: 1em;
            }
            @include media($tablet) {
                text-align: center;
                padding-top: 1em;
                padding-bottom: 2em;
                ul {
                    margin: 1em auto;
                    list-style: inside;
                }
            }
        }
    }

    .tip {
        color: palette(aap-blue, darker);
    }

    .icon {
        margin: 3em auto;
        display: block;
        width: 6rem;
        height: 6rem;
        padding: 1.5rem;
        background: palette(aap-orange, light);
        border-radius: 50%;
        position: relative;

        &::before {
            content: '';
            display: block;
            height: .25rem;
            width: 250%;
            background-color: palette(aap-orange, light);
            position: absolute;
            top: 50%;
            left: -250%;
            z-index: 0;
        }

        svg {
            position: relative;
            fill: palette(aap-white, x-light);
            width: 3rem;
            height: 3rem;
            z-index: 1;
        }
    }
}
