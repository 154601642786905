.progress {
    width: 100%;
    margin: 40px auto;

    text-align: center;

    .circle,
    .bar {
        display: inline-block;

        width: 2rem;
        height: 2rem;

        border: 2px solid palette(aap-blue, dark);
        border-radius: 1rem;
        background: palette(aap-white, x-light);

        &.active {
            background: palette(aap-blue, dark);
        }
    }

    .bar {
        position: relative;
        top: -.9rem;

        width: 3%;
        height: 2px;
        margin-right: -5px;
        margin-left: -5px;

        border-right: 0;
        border-left: 0;
        border-radius: 0;
        background: palette(aap-blue, dark);
    }
}
