// Using bourbon font face mixin
// all target browsers understand either woff or woff2 now
// Museo Regular
@include font-face('Museo', '../fonts/museo-regular-300', 300, $file-formats: woff2 woff);
@include font-face('Museo', '../fonts/museo-regular-500', 500, $file-formats: woff2 woff);
@include font-face('Museo', '../fonts/museo-regular-700', 700, $file-formats: woff2 woff);


// Museo Sans
@include font-face('Museo Sans', '../fonts/museo-sans-300', 300, $file-formats: woff2 woff);
@include font-face('Museo Sans', '../fonts/museo-sans-500', 500, $file-formats: woff2 woff);
@include font-face('Museo Sans', '../fonts/museo-sans-700', 700, $file-formats: woff2 woff);
