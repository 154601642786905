// Typo
$line-height: 1.4;
$font-family: $helvetica;

// Others
$border-radius: 6px;
$font-color: palette(aap-black, mid-dark);
$border-color: palette(aap-white, mid-light);
$border: 1px solid $border-color;
$background-color: palette(aap-white, x-light);
$spacing: $line-height * 1em;
$small-spacing: $spacing / 2;

// Form
$form-box-shadow: inset 0 1px 3px rgba(palette(aap-black, x-dark), .06);
$form-box-shadow-focus: $form-box-shadow, 0 0 5px adjust-color(palette(aap-blue, dark), $lightness: -5%, $alpha: -.3);
$error-color: #f00;

// Animations
$duration: 200ms;
$timing: ease;

// Error Pages
$error-grey: #ccc;
$error-light-grey: #ddd;
