.faqs {
    h1 {
        text-align: center;
    }
    h3 {
        @include font('Museo', 300);
        color: palette(aap-blue, dark);
    }
    dl {
        border-bottom: $border;
    }
    dt {
        padding: 1em 2em 1em 1.5em;
        line-height: 1.2;

    }
    dd {
        color: palette(aap-black, mid-dark);
        padding: 2em;

        &.closed {
            display: none;
        }

        li {
            margin-left: 4em;
            line-height: 2rem;
        }
    }
    .faq-notfound {
        display: none;
    }
    .muted-background {
        dl {
            background: palette(aap-white, x-light);
        }
    }
}
