.gallery {
    position: relative;

    @include outer-container(100%);

    .replace {
        display: none;
    }

    .uploading {
        display: none;
        .bar {
            float: left;

            width: 15px;
            height: 6px;

            animation: loading 2s cubic-bezier(.17, .37, .43, .67) infinite;

            border-radius: 2px;
            background-color: palette(aap-blue, dark);
        }
    }

    > div {
        @include span-columns(6);
        @include omega(2n);

        [type='radio'] {
            display: none;

            &:checked + label {
                img {
                    border: solid 4px palette(aap-blue, dark);
                }

                svg {
                    display: block;
                }
            }
        }

        img {
            width: 100%;

            cursor: pointer;

            border: solid 4px transparent;
        }

        label {
            position: relative;

            svg {
                position: absolute;
                top: $small-spacing;
                left: $small-spacing;

                display: none;

                width: 3em;
                height: 3em;

                fill: palette(aap-blue, dark);
            }

            &.file {
                margin: $small-spacing / 2 0 $spacing * 2 0;

                cursor: pointer;
                text-align: center;
                text-transform: none;

                color: palette(aap-white, mid-dark);

                font-size: 1rem;

                .error {
                    display: none;
                }
            }
        }
    }
}

.muted-background {
    background-color: palette(aap-white, light);

    dl {
        border-bottom: $border;
    }

    h2,
    p,
    dt,
    dd {
        padding-right: $spacing;
        padding-left: $spacing;
    }

    dt,
    dd {
        margin: 0;
    }

    dt {
        position: relative;

        cursor: pointer;

        color: palette(aap-white, dark);
        border-top: $border;

        font-size: 1.2rem;
        line-height: 3;

        &:after {
            position: absolute;
            top: .2em;
            right: 0;

            width: 2.5em;
            height: 2.5em;

            content: '';
            transform: rotate(180deg);

            background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAIAAAACACAYAAADDPmHLAAAABHNCSVQICAgIfAhkiAAAAAlwSFlzAAALEwAACxMBAJqcGAAAAOtJREFUeJzt0bFNQwEMRdGXsZiLFsgbgMWYCgoamkhRlB+L+BzJve2bAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAABwuNP0Ahe8JHmdXuKOvpN8JvmaXuQ/aX4f9wzzdt/X7NHMxxN/WDMfUfxhzXxM8Yc181HFH9bMxxV/WDMf+dK8H3c2fzXzscUf1sxHF39YI/56jfjrNeKv14i/XnN8/I9HHcNtGvHXa8RfrxF/vUb89Rrx12vEX6+5Pv55ZkWO1oi/XiP+eo346zXir9eIDwAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAPNgPwESkVWmaAb8AAAAASUVORK5CYII=');
            background-repeat: no-repeat;
            background-position: center right;
            background-size: contain;
        }

        &.closed {
            &:after {
                transform: rotate(0deg);
            }
        }
    }

    dd {
        .form-control {
            overflow-y: hidden;

            transition-timing-function: cubic-bezier(0, 1, .5, 1);
            transition-duration: .3s;
            transition-property: max-height;

            &.lifestyle {
                max-height: 770px;
            }

            &.personality {
                max-height: 470px;
            }
        }

        &.closed {
            .form-control {
                max-height: 0;

                transition-timing-function: cubic-bezier(0, 1, .5, 1);
                transition-duration: .3s;
                transition-property: max-height;
            }
        }
    }

}

.youtube-player {
    width: 100%;

    img,
    iframe {
        width: 100%;
    }

    iframe {
        min-height: 300px;
    }

    > div {
        cursor: pointer;

        @include play-button(4em, 4em, 1, .5);
    }
}

.no-bottom-margin {
    @extend %no-bottom-margin;
}
.auth {

    .terms {
        .emph {
            color: palette(aap-black, x-dark);
        }
    }

    ol {
        li {
            margin-bottom: $small-spacing;

            color: palette(aap-black, dark);
        }
    }
}
