.error-page {
    .wrap {
        margin-top: 2em;
        text-align: center;

        svg {
            width: 8em;
            fill: palette(aap-white, dark);
        }

        p {
            max-width: 40em;
            margin: 1.5em auto;
        }

        .button {
            font-size: 1.2rem;
            text-decoration: none;
            background-color: $error-grey;
            &:hover { background-color: $error-light-grey; }
            &:visited { };
        }
    }
}
