header {
    .logo {
        position: relative;

        display: block;

        @include span-columns(7);
        @include media($tablet) {
            @include span-columns(12);
        }
    }
    .tagline {
        margin-bottom: 1px;

        font-size: 13px;

        @include font('Museo', 500);
        @include media($phone) {
            display: none;
        }
    }
}
