div.facebook-share-holder {
    border-top:solid 1px #979797;
    padding-top: 120px;
    padding-bottom: 120px;
    margin-top:40px;

    @include media($phone) {
        margin-top: 0px;
        padding-top: 0px;
        padding-bottom: 30px;
        border-top: none;
    }
}

div.clear {
    @include clearfix;
}

div.owner-dash-form {
    max-width: 34%;
    margin-left: auto;
    margin-right: auto;

    @include media($phone) {
        max-width: 90%;
    }

    form.delete-pet-form {
        div.confirm-delete {
            border: solid 2px palette(aap-white, border-grey);
            padding: 5%;
            border-radius: 6px;
            &.has-error {
                border: 1px solid $error-color;
            }
        }
        
        div.closed {
            display: none;
        }
        
        label {
            display: inline;
            text-transform: none;
            color: inherit;
            font-weight: normal;
        }

        ul {
            list-style: none;
            padding-left: 0px;
        }

    }
    
    ul,
    div {
        &.errors {
            display: none;
        }
    }
    .errors {
        padding: $spacing;

        color: $error-color;
        //display: none;
        border: solid 1px $error-color;
        border-radius: $border-radius;

        li {
            margin-left: $spacing;
        }
    }
}

div.delete-pet-success {
    max-width: 38%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 4em;
    
    @include media($phone) {
        max-width: 90%;
    }
}

div.edit-pet-header {
    display: table;
    width: 100%;
    padding-bottom: 30px;
    border-bottom: 2px solid palette(aap-white, border-grey);
    margin-top: 5em;

    @include media($phone) {        
        max-width: 100%;
        display: block;
    }
    
    div.header-column {
        float: left;
        display: inline-block;
        &.pet-name {
            float: left;
            width: 20%;
            h1 {
                font-size: 32px;
            }
            @include media($phone) {
                padding-left: 5%;
                padding-right: 5%;
                display: block;
                width: 100%;
                float: none;
            }
        }

        &.profile-button {
           // margin-left: -26px;
        }

        @include media($phone) {
            display: block;
            width: 100%;
            float: none;
        }
    }

    div.clear {
        @include clearfix;
    }

    div.pet-profile-status {
        width: 16%;
        display: inline-block;
        float: left;
        @include media($phablet) {
            width: 100%;
            padding-left: 5%;
            padding-right: 5%;
        }

		div.inline-form-field {
			background-color: palette(aap-mono, lightest);
			border: 1px solid palette(aap-mono, mid-light);
			border-radius: 6px;
			height: 58px;
			margin-bottom: 20px;

			&.active {
				border: solid 1px palette(aap-blue, blue);
				border-radius: 6px;
			}

			&.error {
				border: solid 1px palette(aap-orange, orange);
				border-radius: 6px;

				div.field {

					select {
						background: url(/img/v2/forms/error-dropdown.svg);
				    	background-repeat: no-repeat;
                        background-position: right 11.9px center;
                        background-size: 14px;
						outline: none;
						font-weight: 300;
						-moz-appearance: none;
						-webkit-appearance: none;
						appearance: none;
						&::-ms-expand { display: none };
					}
					input {
						height: 26px;
						border: none;
						color: palette(aap-mono, dark);
						font-size: 20px;
						font-weight: 300;
	    				line-height: 1.3;
					    outline: none;
						-moz-appearance: none;
						-webkit-appearance: none;
						appearance: none;
						padding-left: 11px;
						height: 56px;
						vertical-align: middle;

						&.show {
							height: 26px;
						}					

						&:focus {
						    outline: none;
							-moz-appearance: none;
							-webkit-appearance: none;
							appearance: none;
						}
					}
				}
			}
			
		    div.field {

		    	label {
		    		color: palette(aap-mono, lightest);
                    text-transform: none;
                    font-weight: 500;
		    		height: 22px;
		    		line-height: 1.57;
    	    		font-size: 14px;
    	    		padding-left: 11px;
    	    		display: none;
                    margin-top: 0;
                    margin-bottom: 0;

		    		&.show {
		    			color: palette(aap-mono, mid-light);
		    			display: block;
		    			padding-top: 5px;
		    		}
		    	}

		    	input {
		    		border: none;
		    		color: palette(aap-mono, dark);
		    		font-size: 20px;
		    		border-radius: 6px;
    	    		line-height: 1.3;
		    	    outline: none;
		    		-moz-appearance: none;
		    		-webkit-appearance: none;
		    		appearance: none;
		    		padding-left: 11px;
		    		height: 56px;
		    		vertical-align: middle;
		    		font-weight: 300;

		    		&.show {
		    			height: 26px;
		    		}
		    		
		    		@include media($phablet) {
		    			font-size: 16px;
		    		}

		    		&:focus {
		    		    outline: none;
		    			-moz-appearance: none;
		    			-webkit-appearance: none;
		    			appearance: none;
		    		}
		    	}

		    	&.has-select {
		    	}

		    	select {
		    		border: none;
		    		color: palette(aap-mono, dark);
                    box-shadow: none;
                    transition: none;
                    padding: 0;
		    		font-size: 20px;
    	    		line-height: 1.3;
    	    		font-weight: 300;
    	    		padding-left: 11px;
    	    		background-color: palette(aap-mono, lightest);
		    	    background-image: url(/img/v2/forms/dropdown.svg);
		    	    background-repeat: no-repeat;
                    background-position: right 11.9px center;
                    background-size: 14px;
		    	    outline: none;
		    		-moz-appearance: none;
		    		-webkit-appearance: none;
		    		appearance: none;
		    		&::-ms-expand { display: none };
		    		height: 56px;
		    		vertical-align: middle;

		    		&.show {
		    			height: 26px;
		    		}
		    		&.unset {
		    			color: palette(aap-mono, mid-light);
		    		}

		    		@include media($tablet) {
		    			margin-top: 0px;
		    		}

		    		@include media($phone) {
		    			margin-top: 0px;
		    			font-size: 16px;
		    		}

		    		&:focus {
		    			outline: none;
		    		}						
		    	}
		    }
        }
    }

    div.pet-profile-buttons {
        width: 64%;
        display: inline-block;
        float:left;
        @include media($phablet) {
            width: 100%;
            padding-left: 5%;
            padding-right: 5%;
        }
        div.header-column {
            img {
                vertical-align: middle;
            }
    
            button#view-pet-link {
                font-family: "Helvetica Neue",Helvetica,Arial,Roboto,"Lucida Grande",sans-serif;
                font-size: 20px;
                line-height: 1.25;
                text-align: left;
                color: #00a8de;
                background-color: palette(aap-mono, lightest);
                border: none;
            }
            
            button#delete-pet-link {
                font-family: "Helvetica Neue",Helvetica,Arial,Roboto,"Lucida Grande",sans-serif;
                font-size: 20px;
                line-height: 1.25;
                text-align: left;
                color: #00a8de;
                background-color: palette(aap-mono, lightest);
                border: none;
            }

            float: left;

            @include media($phone) {
                display: block;
                width: 100%;
                float: none;
            }
        }
        @include media($phone) {
            display: block;
            width: 100%;
            float: none;
        }
    }
}

hr {
    color: palette(aap-white, border-grey);
}

div.buttons {
    margin-top: 5em;

    input[type="submit"] {
        width: 8em;
    }

    button {
        width: 8em;
    }
}

button.grey {
    background-color: palette(aap-white, x-light);
    color: palette(aap-white, dark);
    border: solid 1px palette(aap-white, dark);

    &.cancel {
        margin-bottom: .7em;
    }

    &:hover {
        background-color: palette(aap-white, light);
    }
}

div.form-control {
    &.has-error {
        border: 1px solid $error-color;
    }
}

div.delete-pet-success {
    div.here-to-help {
        margin-top: 8em;
    }
}

div.adopter-select-modal {
    width: 100%;
    div.adopters {
        @include span-columns(7 of 12);
        @include media($phablet) {
            @include span-columns(4 of 4);
            padding-left: 5%;
            padding-right: 5%;
            margin-top: 20px;
        }
        div.single-adopter {
            border: solid 2px #d9dce0;
            padding: 20px;
            margin-bottom: 10px;
            color: #B3B3B3;
            border-radius: 6px;
            font-size: 20px;

            &.selected {
                border: solid 2px palette(aap-blue, blue);
                color: #808080;
            }
        }
    }


    div.adopter-select-modal-send {
        @include span-columns(5 of 12);
        @include media($phablet) {
            @include span-columns(4 of 4);
            padding-left: 5%;
            padding-right: 5%;
        }
        position: relative;
        height: 78%;

        button {
            position: absolute;
            bottom:0;
            width: 50%;
            right: 0;
            padding-top: 5px;
            padding-bottom: 5px;
            color: #ffffff;
            background-color: #00a8de;
            @include media($phablet) {
                width: 100%;
            }

            &:hover {
				box-shadow: inset 0 -3px 0px 0px rgba(0, 0, 0, 0.4);
				background-color: palette(aap-blue, dark);
                transition: all 0.3s ease 0s;
                &:active {
				    box-shadow: inset 0 3px 4px 0px rgba(0, 0, 0, 0.4);
				    background-color: palette(aap-blue, blue);
                }
            }


            &:disabled {
                background-color: palette(aap-mono, light);
                color: palette(aap-mono, lightest);
                border: 1px solid palette(aap-mono, light);

                &:hover {
                    background-color: palette(aap-mono, light);
                    color: palette(aap-mono, lightest);
                    border: 1px solid palette(aap-mono, light);
                    &:active {
                        background-color: palette(aap-mono, light);
                        color: palette(aap-mono, lightest);
                        border: 1px solid palette(aap-mono, light);

                    }
                }
            }
        }
    }
}
