.page-footer {
    background-color: palette(aap-white, mid-dark);
    .links {
        color: palette(aap-white, x-light);
        li {
            display: inline-block;

            padding-right: 38px;
            padding-left: 0;

            list-style: none;

            vertical-align: top;

            font-size: 14px;
            font-weight: bold;

            @include media($tablet-plus) {
                padding-right: 31px;

                font-size: 13px;
            }
            @include media($tablet) {
                padding-right: 52px;
            }
            @include media($phone) {
                display: block;

                width: 100%;
                padding: 8px 0;

                border-top: 1px solid palette(aap-white, x-light);
            }
            @include media($phone) {
                .is-open {
                    display: block;
                }
            }
            ul {
                padding: 0;

                @include media($phone) {
                    display: none;
                }

                li {
                    display: block;

                    padding-right: 0;

                    font-weight: normal;

                    @include media($phone) {
                        border: 0;
                    }
                }
            }
        }
        a {
            text-decoration: none;

            color: palette(aap-white, x-light);
        }
    }
    .social {
        @extend %text-center;
        form {
            position: relative;

            [type='text'],
            .newsletter-button {
                display: inline-block;
                padding: 12px 14px;
                font-size: 12px;


            }

            [type='text'] {

                width: 255px;

                border: solid 1px palette(aap-white, x-light);
                border-top-left-radius: 6px;
                border-bottom-left-radius: 6px;

                @include media($tablet-plus) {
                    width: 220px;
                }

                &.error {
                    color: $error-color;
                    border-color: $error-color;
                }
            }
            span {
                &.error {
                    position: absolute;
                    top: -20px;
                    left: 0;

                    display: block;

                    width: 100%;

                    text-align: center;

                    color: $error-color;
                }
            }
            .newsletter-button {
                font-family: $helvetica;
                margin-top: 11px;
                margin-left: -10px;
                padding: 14px;

                cursor: pointer;

                border: solid 1px palette(aap-white, x-light);
                border-top-left-radius: 0;
                border-top-right-radius: 6px;
                border-bottom-right-radius: 6px;
                border-bottom-left-radius: 0;

                font-size: 16px;
            }
        }
        p {
            color: palette(aap-white, x-light);

            font-weight: normal;
            line-height: 22px;
        }
        ul {
            width: 210px;
            margin-right: auto;
            margin-left: auto;
            padding-left: 0;

            list-style: none;

            @include clearfix;
            @include media($phone) {
                width: 100%;
                margin-top: 20px;
            }

            li {
                float: left;

                @include media($phone) {
                    display: inline-block;
                    float: none;
                }

                a {
                    display: block;

                    width: 32px;
                    height: 32px;
                    margin-right: 10px;
                    padding: 7px;

                    border-radius: 16px;
                    background-color: palette(aap-white, x-light);
                }
            }
            svg {
                width: 100%;
                height: 100%;

                fill: palette(aap-white, mid-dark);
            }
        }
    }
    .info {
        p {
            color: palette(aap-white, x-light);

            font-size: 12px;
            line-height: 18px;
            margin: 1rem 0;

            @extend %text-center;
            @include media($phone) {
                text-align: left;
            }

            a {
                text-decoration: underline;

                color: palette(aap-white, x-light);
                &.sponsor {
                    font-size: 14px;
                }
            }
        }
    }
}

.copyright {
    display: block;

    padding: 15px 0;

    text-align: center;

    background-color: palette(aap-white, dark);
    p {
        margin: 0;

        color: palette(aap-white, x-light);

        font-size: 13px;
    }
}
.suggestions {
    button {
        width: 49%;
    }
}
.suggestions-trigger {
    input {
        display: none;
    }
    label {
        display: inline;
        color: palette(aap-white, x-light);
        font-weight: normal;
        font-size: 12px;
        line-height: 18px;
        text-decoration: underline;
        text-transform: none;
        cursor: pointer;
    }
}
