header {
    .sponsor-logos {
        @include span-columns(5);
        @include omega;
        @include media($tablet) {
            @include span-columns(12);
        }
        @include media($phone) {
            @include span-columns(12);
        }

        div {
            float: left;

            margin-left: 5px;
        }
        .sponsor-text {
            float: left;

            width: 134px;
            margin-right: 10px;
            margin-bottom: 5px;

            font-size: 12px;
            font-weight: normal;
            line-height: 1.42857143;

            @extend %text-left;
            @include font(museo_sans300);
            @include media($tablet) {
                width: 140px;
            }
            @include media($phone) {
                float: none;

                width: 100%;
            }
        }
        .purina-logo {
            margin-bottom: 20px;
            margin-left: 0;

            @include media($tablet) {
                clear: none;
            }
            @include media($phone) {
                clear: both;
            }
        }
    }
}
