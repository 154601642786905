body {
    background-image: url('../img/header/header_background_desktop.jpg');
    background-repeat: no-repeat;
    background-position: center top;
    &.error-page {
        background-image: url('../img/header/header_background_mobile.jpg');
        background-size: 100% 42%;
    }

    @include media($tablet) {
        background-image: url('../img/header/header_background_mobile.jpg');
    }
}
