.nav {
    background-color: palette(aap-blue, darker);

    li {
        list-style: none;
    }
    .nav-toggler {
        display: none;
        color: palette(aap-white, x-light);
        line-height: 3;
        margin: 0;
        cursor: pointer;
        @include media($tablet) {
            display: block;
        }
    }
    .nav-menu-item {
        float: left;
        position: relative;
        @include media($tablet) {
            float: none;
        }
    }
}

.login {
    margin: .5em 0 0;

    &:hover {
        background-color: palette(aap-white, x-darker);
    }

    a {
        border: solid 1px palette(aap-white, x-light);
        line-height: 2;
        margin-top: .45em;
        padding: .4em 1.5em;
        text-transform: uppercase;
        text-decoration: none;
        color: palette(aap-white, x-light);
        border-radius: $border-radius;

        &:hover {
            background-color: palette(aap-white, x-light);
            color: palette(aap-blue, darker);
        }
    }
}
.nav-menu {
    margin: 0;
    padding: 0;
    line-height: 3;

    @include media($tablet) {
        display: none;
        &.is-open {
            display: block;
            background: palette(aap-blue, light);
        }
    }

    a {
        padding: 0 2em;
        color: palette(aap-white, x-light);
        text-decoration: none;
        text-transform: uppercase;
        @include media($large-desktop) {
            padding: 0 2.2em;
        }
        @include media($tablet) {
            padding: 0 .5em;
        }
    }

    li {
        background-color: transparent;
        cursor: pointer;
        @include media($tablet) {
            background: palette(aap-blue, darker);
        }
        &:hover {
            background: palette(aap-blue, accent-dark);
            .nav-submenu {
                position: absolute;
                display: block;
                padding: 0;
                @include media($tablet) {
                    position: static;
                    display: none;
                }
            }
        }
 
        .nav-submenu {
            z-index: 30000;
            display: none;
            padding: 0;
            width: 20em;
            @include media($tablet) {
                position: static;
                display: none;
                width: 100%;
                &.is-open {
                    display: block;
                }
            }
            li {
                border-bottom: 1px solid palette(aap-blue, accent-light);
                background: palette(aap-blue, accent-dark);
                &:hover {
                    background: palette(aap-blue, accent-darker);
                }
                a {
                    text-transform: none;
                    letter-spacing: normal;
                }
            }
        }
    }
}

div#mobile-menu-active-item {
    display: none;
    line-height: 3;
    background: palette(aap-white, light);
    width: 100%;
    padding: 0em 1em;
    font-weight: 600;
    color: palette(aap-blue, darker);
    
    @include media($tablet) {
        display: block !important;
    }
}
