@import "awesomplete";
// Google upload
.post-sign-in {
  display: none;
}

.during-upload {
  display: none;
}

.post-upload {
  display: none;
}

#channel-name {
  font-size: 60px;
  margin-left: 10px;
}
section.pagination {
    @include span-columns(12 of 12);
    text-align: center;
    div.pagination-wrapper {
        margin-top: 60px;
        a {
            &.previous:after {
                content: '';
                display: inline-block;
                width: 15px;
                height: 15px;
                border-top: 2px solid #333;
                border-right: 2px solid #333;
                -moz-transform: rotate(-135deg);
                -webkit-transform: rotate(-135deg);
                transform: rotate(-135deg);
            }
            &.previous.disabled:after {
                content: '';
                display: inline-block;
                width: 15px;
                height: 15px;
                border-top: 2px solid rgba(179, 179, 179, 1.00);
                border-right: 2px solid rgba(179, 179, 179, 1.00);
                -moz-transform: rotate(-135deg);
                -webkit-transform: rotate(-135deg);
                transform: rotate(-135deg);
            }

            &.next:after {
                content: '';
                display: inline-block;
                width: 15px;
                height: 15px;
                border-top: 2px solid #333;
                border-right: 2px solid #333;
                -moz-transform: rotate(45deg);
                -webkit-transform: rotate(45deg);
                transform: rotate(45deg);
            }
            &.next.disabled:after {
                content: '';
                display: inline-block;
                width: 15px;
                height: 15px;
                border-top: 2px solid rgba(179, 179, 179, 1.00);
                border-right: 2px solid rgba(179, 179, 179, 1.00);
                -moz-transform: rotate(45deg);
                -webkit-transform: rotate(45deg);
                transform: rotate(45deg);
            }
        }

        span.page-count {
            margin-left: 40px;
            margin-right: 40px;
            color: #242424;
            font-weight: 200;
            letter-spacing: 0;
            font-size: 20px;
        }
    }

    div.pet-count-text {
        margin-top: 40px;
        margin-bottom: 80px;
        span.pet-count {
            color: #242424;
            font-weight: 200;
        }
    }
}