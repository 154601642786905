// break points
$large-desktop: new-breakpoint(min-width 1200px 12);
$tablet-plus: new-breakpoint(max-width 1199px 12);
$tablet: new-breakpoint(max-width 991px 12);
$phone: new-breakpoint(max-width 769px 12);
$smalltablet: new-breakpoint(max-width 900px 12);
$phablet: new-breakpoint(max-width 799px 4);
$largephone: new-breakpoint(max-width 767px 4);
$midphone: new-breakpoint(max-width 655px 4);
$iphone: new-breakpoint(max-width 435px 4);
$smallphone: new-breakpoint(max-width 400px 4);

// Default font
$primaryFont: $helvetica;

//colors
$default-colors: (
    aap-black: (
        x-dark: #000,
        dark: #242424,
        mid-dark: #666,
        mid-light: #4d4d4d,
        x-light: #999
    ),
    aap-white: (
        dark: #808080,
        mid-dark: #9f9f9f,
        mid-light: #b3b3b3,
        light: #f2f2f2,
        x-light: #fff,
        border-grey: #d9dce0,
    ),
    aap-mono: (
        dark: #4d4d4d,
        grey: #808080,
        light-grey: #242424,
        mid-light: #b3b3b3,
        light: #dfdfdf,
        lightest: #ffffff,
    ),
    aap-blue: (
        x-light: #bde1f4,
        light: #6fcbf0,
        blue: #00a8de,
        mid-light: #6bcbf2,
        dark: #00a8de,
        darker: #1d72c6,
        accent: #66bfe3,
        accent-light: #73ccef,
        accent-dark: #08ade0,
        accent-darker: #0090bd
    ),
    aap-green: (
        light: #77d329,
        dark: #70cf36
    ),
    aap-orange: (
        light: #fec653,
        dark: #ff9a55,
        orange: #ff9a55,
        mid-light: #fcb17d,
        darkest: #e15b00,
    ),
    aap-red: (
        x-dark: #660000,
        dark: #b20000,
        mid-dark: #cc0000,
        mid-light: #e50000,
        light: #ff0000,
        lighter: #ff6666,
        x-light: #ffcccc,
    )
) !default;
