
.adoption-application {
    @include outer-container(1200px);
    &.form {
        @include outer-container(26.875em);
    }
    h1 {
        margin-left: $small-spacing;
    }

    ol {
        li {
            margin: $spacing 0;
        }
    }

    label {
        display: none;
    }

    .results {
        @include span-columns(8);

        @include media($tablet) {
            @include span-columns(12);
        }

        .actions {
            @include outer-container;
            padding: $spacing;
            .small {
                @include span-columns(4);
            }

            .button {
                margin-top: $small-spacing;
                margin-bottom: $small-spacing;
                text-align: center;
            }

            .download {
                @include media($phone) {
                    span {
                        display: none;
                    }
                }
            }
        }
        .result {
            margin-left: $spacing;
            p {
                @extend %no-top-margin;
                font-size: 1.2rem;
                background-color: palette(aap-white, light);
                padding: $small-spacing;
            }
        }

        h3 {
            &[id^='title'] {
                font-size: 1.5rem;
            }
        }
        div {
            &[id^='instruct'] {
                display: none;
            }
        }

        h4 {
            font-size: 1.2rem;
            color: palette(aap-black, mid-dark);
            font-weight: normal;
            @extend %no-bottom-margin;
        }
    }
    .guidance {
        @include span-columns(4);
        background: palette(aap-white, x-light);

        @include media($tablet) {
            position: fixed;
            left: 0;
            top: 0;
            max-height: 90vh;
            overflow: auto;
            width: 100%;
            margin-left: -100%;
            @include transition;
            dl {
                margin-top: 0;
            }
            h3 {
                display: none;
            }

            &.open {
                margin-left: 0;
            }
        }
    }
    .main {
        margin: $spacing;
    }
    .guidance {
        padding: $small-spacing;
    }

    button {
        &.adoption {
            margin-top: $spacing;
        }
    }
}
.guidance-button {
    display: none;

    @include media($tablet) {
        display: block;
        position: fixed;
        bottom: 0;
        right: 0;
        left: 0;
        margin: 0;
        text-align: center;
    }
}
