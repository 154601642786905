.page-footer {
    margin-top: 20px;
    padding: 60px 90px;

    @include outer-container(100%);
    @include media($tablet-plus) {
        padding: 60px 0;
    }
    @include media($tablet) {
        padding: 60px 0;
    }
    @include media($phone) {
        padding: 0 10px;
        padding-bottom: 20px;
    }

    .footer-wrapper {
        @include media($tablet-plus) {
            @include outer-container(977px);
        }
        @include media($tablet) {
            @include outer-container(750px);
        }
    }

    .info,
    .actions {
        @include span-columns(12);
        @extend %text-left;
        @include media($phone) {
            @include span-columns(12);
        }
    }

    .links {
        padding: 0;

        list-style: none;

        @include span-columns(8 of 12);
        @include shift(-12);
        @include media($tablet) {
            padding-bottom: 20px;

            border-bottom: 1px solid palette(aap-white, x-light);

            @include span-columns(12 of 12);
            @include shift(0 of 12);
        }
        @include media($phone) {
            padding-bottom: 0;
        }


    }

    .social {
        @include span-columns(4 of 12);
        @include shift(8);
        @include media($tablet) {
            padding-bottom: 20px;

            border-bottom: 1px solid palette(aap-white, x-light);

            @include span-columns(12 of 12);
            @include shift(0 of 12);
        }
        @include media($phone) {
            margin-bottom: 20px;
            padding-right: 0;
            padding-bottom: 0;

            border: 0;
        }


    }
}

.copyright {
    @include span-columns(12);
}
