// @extend %text-center;
%text-center {
    text-align: center;
}
%text-left {
    text-align: left;
}
%text-right {
    text-align: right;
}
%no-top-margin {
    margin-top: 0;
    padding-top: 0;
}
%no-bottom-margin {
    margin-bottom: 0;
    padding-bottom: 0;
}
