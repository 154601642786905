.edit-pet {
    table {
        margin: $spacing 0;
    }
    th {
        text-align: left;
        padding-left: $small-spacing;
        padding-top: $small-spacing;
    }
    input {
        display: inline-block;
    }

    .editable {
        display: block;
    }

    span {
        &.error {
            position: static;
            display: none;
        }
    }

    tr {
        &:not(.box) {
            th,
            td {
                display: block;
            }
        }
    }

    td {
        [type='text'],
        textarea {
            display: none;
        }
        div {
            position: relative;
        }
    }
    .tables {

        &.muted-background {
            tbody {
                tr {
                    td,
                    th {
                        background-color: palette(aap-white, light);
                    }
                }
            }

        }
        tbody {
            tr {
                &:hover > td,
                &:hover > th {
                    background-color: transparent;
                }

                &:nth-child(even) {
                    background-color: transparent;
                    &:hover > td {
                        background-color: transparent;
                    }
                }
            }
        }

    }
    .counter {
        display: none;
    }
}


.loading-overlay {
    top:0;
    bottom:0;
    right:0;
    left:0;
    position:fixed;
    z-index:9999;
    background-color:rgba(0,0,0,0.65);
    background-image: url(/img/modules/loading.svg);
    background-repeat: no-repeat;
    background-position: center;
}

.edit-pet-dash {
    @include outer-container(1200px);

    .flash-success {
        background-color: #e6efc2;
        color: darken(#e6efc2, 60%);
        display: block;
        margin-bottom: 1.5em / 2;
        padding: 1.5em / 2;
        text-align: center;
        a {
            color: darken(#e6efc2, 70%);
            text-decoration: none;
            float: right;
            font-size: 25px;
            line-height: 1;
            &:focus,
            &:hover {
                color: darken(#e6efc2, 90%);
            }
        }
    }

    input.ajax-save-success, select.ajax-save-success {
        background-image: url('/img/modules/checkmark.svg') !important;
        background-repeat: no-repeat;
        background-position: right;
        background-attachement: fixed;
    }

    .media-wrap,
    .story {
        padding: 0 $small-spacing;
    }

    .media {
        @include outer-container(100%);
        .thumb {
            @include span-columns(4);
            @include omega(3n);
            @include media($tablet) {
                @include span-columns(12);
            }
            background: palette(aap-white, light);
            min-height: 300px;
            margin-bottom: $spacing;
        }
    }

    .youtube-player {
        div {
            img {
                max-height: 300px;
                max-width: 50%;
                @include media($phone) {
                    max-width: 100%;
                }
            }
        }
    }

    .pet-select {
        width: 300px;
    }

    .left,
    .right {
        @include span-columns(6);
        padding: 0 $small-spacing;

        @include media($phone) {
            @include span-columns(12);
        }
    }

    .others {
        @include clearfix;
        padding: 0 $small-spacing;

        .form-control {
            @include outer-container(100%);
            div {
                @include span-columns(3);
                @include omega(4n);
                @include media($phone) {
                    @include span-columns(12);
                }
            }
        }
    }

    .gallery {
        .edit-gallery {

        }

        label {
            
            button.edit-photo {
                background: palette(aap-white, light);
                position: absolute;
                top: 12px;
                right: 12px;
                padding: 0.4em;
            }
            button.edit-photo::before {
                font-family: 'FontAwesome';
                z-index: 100001;
                font-size: 28px;
                content: "\f040";
                color: palette(aap-white, dark);
            }
            ul.edit-photo-menu {
                position: absolute;
                top: 30px;
                list-style: none;
                width: 60%;
                right: 16px;
                background: palette(aap-white, light);
                font-weight: normal;
                text-transform: none;
                padding-left: 0px;
                display: none;
                
                li {
                    border-bottom: 1px solid palette(aap-white, dark);
                    padding: .3em;
                }

                li:last-child {
                    border-bottom: none;
                }
            }
        }
    }

}
.banner {
    min-height: 5em;
    padding: 2em;
        max-width: 1170px;
    margin: 40px auto;
    text-align: center;

    &.pet-completion-notice {
        background: palette(aap-blue, x-light);
    }

    &.pet-documents-notice {
        background: palette(aap-orange, light);
    }

    &.pet-complete-adoption-notice {
        background: palette(aap-green, light);
    }

    p {
        font-size: 1.5em;
        color: palette(aap-black, light);
    }
    button.close-button {
        float: right;
        display: inline-block;
        padding: 2px 5px;
        background: transparent;
    }

    button#close::before {
        font-family: Arial, "Helvetica CY", "Nimbus Sans L", sans-serif !important;
        font-size: 25px;
        line-height: 35px;
        width: 35px;
        content: "\00d7";
    }
}

div.pending-adoption-warning {
    border: 3px solid orange;
    width: 88%;
    text-align: center;
    margin: 0 auto;
    margin-top: 20px;
    margin-bottom: 20px;

    p {
        display: inline-block;
        color: orange;
        font-size: 16px;
        font-weight: bold;
        width: 100%;
        margin: 0;
        padding: 10px;
    }

}
