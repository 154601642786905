div.example2 {
    div.error {
        display: none;
        margin-top: 15px;

        svg {
            vertical-align: middle;
        }

        &.visible {
            display: block;
        }
    }
}