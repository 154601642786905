div.single-application {
    height: 125px;
    margin-bottom: 20px;
    font-size: 20px;
    border: 1px solid #b3b3b3;
    padding: 30px 50px 30px 50px;
    border-radius: 6px;

    &.unseen {
        border-left: 5px solid #00a8de;
    }

    @include media($tablet) {
        height: 205px;
        padding: 30px 30px 30px 30px;
    }

    @include media($midphone) {
        padding: 30px 0px 30px 15px;
    }

    @include media($iphone) {
        font-size: 16px;
    }

    div.application-details {
        width: 30%;
        float: left;

        span.application-date {
            color: #808080;
            font-weight: 100;
        }
    }

    div.form-control {
        width: 70%;
        display: inline-block;;
        color: #b3b3b3;
        margin-top: 20px;

        span {
            width: 30%;
            display: inline-block;
            a {
                color: #00a8de;
                text-decoration: none;
                cursor: pointer;
                font-weight: bold;
                font-size: 16px;
                white-space: nowrap;

                &.previous-app-step {
                    color: #4a4a4a;
                    cursor: pointer;
                    font-weight: 300;
                    font-size: 16px;
                }
                &.disabled {
                    color: #b3b3b3;
                    font-weight: 200;
                    cursor: not-allowed;
                    font-size: 16px;
                }
                &.pending-adoption {
                    color: orange;
                    cursor: not-allowed;
                    font-weight: 300;
                }
            }
        }
        @include media($tablet) {
            margin-top: 10px;
            span {
                @include fill-parent;
                display: block;
                margin-left: 50%;
                margin-bottom: 15px;
                
                a {
                    color: #00a8de;
                    text-decoration: none;
                    cursor: pointer;
                    font-weight: bold;
                    font-size: 16px;

                    &.previous-app-step {
                        color: #4a4a4a;
                        cursor: pointer;
                        font-weight: 300;
                        font-size: 16px;
                    }
                    &.disabled {
                        color: #b3b3b3;
                        font-weight: 200;
                        cursor: not-allowed;
                        font-size: 16px;
                    }
                    &.pending-adoption {
                        color: orange;
                        cursor: not-allowed;
                        font-weight: 300;
                    }
                }
            }
        }
        @include media($phablet) {
            span {
                margin-left: 0;
            }
        }
    }

    div.noapptext {
        margin-top: 15px;
        color: #b3b3b3;
    }
}

div.tab_content {
    padding: 20px;
    display: none;
    margin: 70px;
    @include media($midphone) {
        margin: 20px;
    }
}
    
