header {
    padding: 4px 8px;

    @include outer-container(1170px);
    @include media($tablet-plus) {
        @include outer-container(977px);
    }
    @include media($tablet) {
        @include outer-container(750px);
    }

}
