.petco {
    h3 {
        @extend %no-bottom-margin;
        font-family: $helvetica;
        color: palette(aap-black, mid-dark);
    }

    a {
        color: palette(aap-blue, dark);
    }

    ol {
        li {
            color: palette(aap-white, dark);
        }
    }

    .view-store-list {
        text-align: center;
        
        a {
            width: 24.6rem;
        }
    }
}

section.petco-landing-page {
  div.clear {
    @include clearfix;
  }
  h1 {
    -webkit-margin-after: 0;
    -webkit-margin-before: 0;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.2;
    letter-spacing: -1.7px;
    font-size: 45px;
    font-family: $primaryFont;
    color: palette(aap-mono, dark);

    &.mega {
      font-size: 64px;

      &.bold {
        font-weight: bold;
      }
    }
  }

  h2 {
    -webkit-margin-after: 0;
    -webkit-margin-before: 0;
    font-family: $primaryFont;
    font-size: 2em;
    line-height: 1.375;
    color: palette(aap-mono, dark);

    &.petco-subheader {
      @include span-columns(10 of 12);
      @include shift(1.3);
      margin-bottom: 80px;
            @include media($phablet) {
        @include span-columns(4 of 4);
        @include shift(0);
        padding-left: 3%;
        padding-right: 3%;
      }
    }

    &.bold {
      font-weight: bold;
    }

    &.medium {
      font-weight: 500;
    }
  }

  h3 {
    -webkit-margin-after: 0;
    -webkit-margin-before: 0;
    font-family: $primaryFont;
    font-size: 26px;
    line-height: 1.5;
    color: palette(aap-mono, dark);
    &.petco-meeting-subheader {
      @include span-columns(7 of 12);
      @include shift(2.6);
      margin-bottom: 80px;

      @include media($phablet) {
        @include span-columns(4 of 4);
        @include shift(0);
        padding-left: 3%;
        padding-right: 3%;
      }
    }
    &.bold {
      font-weight: bold;
      font-style: normal;
      font-stretch: normal;
      letter-spacing: -1px;
    }

    &.thin {
      font-weight: 100;
      font-style: normal;
      font-stretch: normal;
      line-height: 0.77;
      letter-spacing: normal;
    }
  }

  h4 {
    -webkit-margin-after: 0;
    -webkit-margin-before: 0;
    font-family: $primaryFont;
    font-size: 20px;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.6;
    letter-spacing: 0.77px;
    color: palette(aap-mono, dark);


    &.bold {
      font-weight: bold;
    }

    &.thin {
      font-weight: 200;
    }

    &.light {
      font-weight: 300;
    }

    &.medium {
      font-weight: 500;
      line-height: 1.3;
      letter-spacing: normal;
    }
    &.petco-intro {
      margin-bottom: 40px;
    }
  }

  h5 {
    -webkit-margin-after: 0;
    -webkit-margin-before: 0;
    font-family: $primaryFont;
    font-size: 1em;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.5625;
    letter-spacing: normal;
    color: palette(aap-mono, dark);

    &.light {
      font-weight: 300;
    }

    &.bold {
      font-weight: bold;
    }

    &.thin {
      font-weight: 200;
    }
  }

  h6 {
    -webkit-margin-after: 0;
    -webkit-margin-before: 0;
    font-family: $primaryFont;
    font-size: 0.875em;
    line-height: 1.57;
    color: palette(aap-mono, dark);

    &.bold {
      font-weight: bold;
    }

    &.medium {
      font-weight: 500;
    }
  }
  @include outer-container;
  padding-top: 120px;

  @include media($phablet) {
    padding-top: 80px;
  }

  h1 {
        -webkit-margin-before: 0;
    text-align: center;
    margin-bottom: 60px;

    @include media($phablet) {
      font-size: 45px;
    }
  }

  h4 {
      @include span-columns(10 of 12);
      @include shift(1.3);
    float: none;
    margin-bottom: -50px;

    @include media($phablet) {
      @include span-columns(9 of 10);
      @include shift(0.5);
    }
  }

  dl.petco-landing-page-items {
    @include span-columns(8 of 12);
    @include shift(2 of 12);
    //@include omega();
    counter-reset: petco-landing-page-counter;
    margin-bottom: 60px;

    &.meeting-tips {
      @include span-columns(10 of 12);
      @include shift(2.6 of 12);
      @include media($phablet) {
      @include span-columns(9 of 10);
      @include shift(0);
      margin-left: 5%;
        margin-right: 5%;
        margin-top: -30px;
    }
    }

    @include media($phablet) {
      @include span-columns(9 of 10);
      @include shift(0);
      margin-left: 5%;
        margin-right: 5%;
        margin-top: -30px;
    }

    dt {
      //padding-top: 80px;
      margin-bottom: 10px;
      margin-top: 40px;

      @include media($phablet) {
        margin-bottom: 20px;
      }

      h2 {
        @include shift(1.5 of 10);
        //margin-top: 80px;
        color: palette(aap-mono, dark);
        &.meeting-tips {
          @include shift(1.3 of 10);
        }

        @include media($phablet) {
          @include shift(0);
          font-size: 26px;
          font-weight: bold;
          margin-left: 28px;
        }
      }

      &:before {
        @include span-columns(1);
        content: counter(petco-landing-page-counter);
        counter-increment: petco-landing-page-counter;
        font-size: 90px;
        font-weight: bold;
        //margin-top: 63px;
        margin-top: -37px;
        color: #4d4d4d;

        @include media($phablet) {
          font-size: 26px;
          font-weight: bold;
          margin-top: -3px;
          content: counter(petco-landing-page-counter) ".";
          margin-right: 0;
        }
      }
    }

    dd {
      @include span-columns(8);
      @include omega();
      padding-left: 84px;
      float: none;
        font-size: 20px;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.3;
        font-weight: 200;
        margin-left: 3.5%;
        padding-top: 0;

        @include media($phablet) {
          @include span-columns(4);
          margin-left: 0;
          margin-bottom: 60px;
          padding-left: 0;
        }
        div.tip {
          @include span-columns(6 of 6);
          margin-top: 20px;
          padding-bottom: 40px;
          float: none;

          div.tip-icon {
            @include span-columns(0.5 of 6);
    
            img {
              width: 31px;
              height: 49px;
            }
          }

          div.tip-text {
            @include span-columns(5.5 of 6);
            padding-left: 15px;
            padding-top: 9px;
              color: palette(aap-mono, grey);
          }
        }
    }
  }

  div.cta {
    @include outer-container;
    @include span-columns(12);
    margin-top: 80px;
    margin-bottom: 120px;
    text-align: center;

    @include media($phablet) {
      margin-top: 0px;
      //margin-bottom: 80px;
    }

    a.blue-button {
      //@include clearfix;
      width: 270px;
      height: 58px;
      border-radius: 6px;
      background-color: palette(aap-blue, blue);
      font-size: 20px;
      font-weight: bold;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.25;
      letter-spacing: -0.8px;
      color: palette(aap-mono, lightest);
      text-decoration: none;
      padding: 15px 80px 18px 83px;

      &:hover {
        box-shadow: inset 0 -3px 0px 0px rgba(0, 0, 0, 0.4);
        background-color: palette(aap-blue, dark);
          &:active {
            box-shadow: inset 0 3px 4px 0px rgba(0, 0, 0, 0.4);
            background-color: palette(aap-blue, blue);
          }
      }
    }
  }
}

.petco-ad-banner {
    background: palette(aap-white, light);
    padding: 0.1em;
    margin: 0 auto;
    width: 75%;
    text-align: center;
    font-weight: 400;

    p {
        font-size: 1.3rem;
        color: palette(aap-black, mid-light);
    }

    a {
        font-size: 1.3rem;
        padding: .5em;

        @include media($midphone) {
              display: block;
              margin-left: 15%;
    margin-right: 15%;
        }
    }
    #close {
        float:right;
        display:inline-block;
        padding:2px 5px;
        background: transparent;
    }

    #close:before {
        font-family: Arial, "Helvetica CY", "Nimbus Sans L", sans-serif !important;
        font-size: 25px;
        line-height: 35px;
        width: 35px;
        content: "\00d7";
    }
}

.petco-legal-disclaimer {
    overflow: scroll;
}

.container{
  width: 100%;
  margin: 0 auto;
}

.petco_map_blurb {
    margin-top: 2em;
}
#map_container{
  position: relative;
}
#map{
    height: 0;
    overflow: hidden;
    padding-bottom: 22.25%;
    padding-top: 30px;
    position: relative;
}

.petco-remodal-close {
  position: absolute;
  top: 0;
  right: 0;

  display: block;
  overflow: visible;

  width: 35px;
  height: 35px;
  margin: 0;
  padding: 0;

  cursor: pointer;
  -webkit-transition: color 0.2s;
  transition: color 0.2s;
  text-decoration: none;

  color: palette(aap-black, mid-light);
  border: 0;
  outline: 0;
  background: transparent;
}

form#record-legal-accept {
    .fieldset {
        text-align: center;
        .form-control {
            label {
                display: inline;
            }
        }
    }
}

.view-store-list {
    margin: 1.5em;
}
