.tables {
    $base-border-color: palette(aap-white, light) !default;
    $base-border-radius: 3px !default;
    $base-line-height: 1.5em !default;
    $base-spacing: 1.5em !default;
    $action-color: palette(aap-blue, dark) !default;
    $dark-gray: palette(aap-black, dark) !default;
    $light-gray: palette(aap-white, light) !default;
    $medium-screen: em(769) !default;
    $large-screen: em(991) !default;
    $base-font-color: $dark-gray !default;
    $table-border-color: $base-border-color;
    $table-border: 1px solid $table-border-color;
    $table-background: lighten($table-border-color, 12%);
    $table-header-background: lighten($table-background, 10%);
    $table-hover-background: darken($table-background, 5%);
    $table-stripe-background: darken($table-background, 2%);
    $table-stripe-background-hover: darken($table-stripe-background, 5%);
    $table-padding: .75em 1em;
    border-collapse: separate;
    border-left: 0;
    border-radius: $base-border-radius;
    border-spacing: 0;
    border: $table-border;
    width: 100%;
    tbody {
        background-color: $table-background;
        td {
            border-bottom: 0;
            border-left: 1px solid $table-border-color;
            border-top: 1px solid $table-border-color;
            padding: $table-padding;
            &:first-child {
                border-left: 0;
            }
            button {
                display: inline-block;
                font-size: .7em;
                line-height: $base-line-height;
                margin-bottom: .3em;
                margin-right: .5em;
                outline: none;
                padding: .3em 1em;
                width: 100%;
                @include media($medium-screen) {
                    margin-bottom: 0;
                    width: auto;
                }
            }
        }
        tr {
            &:hover > td,
            &:hover > th {
                background-color: $table-hover-background;
            }

            &:nth-child(even) {
                background-color: $table-stripe-background;
                &:hover > td {
                    background-color: $table-stripe-background-hover;
                }
            }
        }

        &:last-child {
            tr {
                &:last-child > td {
                    &:first-child {
                        border-bottom-left-radius: $base-border-radius;
                    }
                }
                &:last-child > td {
                    &:last-child {
                        border-bottom-right-radius: $base-border-radius;
                    }
                }
            }
        }
    }
    thead {
        &:first-of-type {
            tr {
                &:first-child > th {
                    &:first-child {
                        border-top-left-radius: $base-border-radius;
                    }
                }
                &:first-child > th {
                    &:last-child {
                        border-top-right-radius: $base-border-radius;
                    }
                }
            }
        }
        th {
            background-color: $table-header-background;
            border-bottom: 0;
            border-left: 1px solid $table-border-color;
            padding: $table-padding;
            text-align: left;
            &:first-child {
                border-left: 0;
            }
        }
    }

}
