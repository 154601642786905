.records {
    @include outer-container(800px);
    padding: 0 $spacing;
    margin-bottom: $spacing * 3;

    .upload-form {
        padding: $spacing;

        .form-control {
            @include outer-container(100%);
            margin: $spacing 0;

            > div {
                @include span-columns(6);

                @include media($phone) {
                    @include span-columns(12);
                }
            }
        }
    }

    .here-to-help {
        text-align: left;
    }

    .button-holder {
        text-align: center;
    }

    label {
        span {
            display: block;
        }
    }

    svg {
        &.file-capture {
            display: none;
            @include media($phone) {
                display: inline-block;
            }
        }

        &.file-upload {
            display: inline-block;
            @include media($phone) {
                display: none;
            }
        }
    }

    .uploading {
        width: 150px;
        margin: $spacing auto;
    }

    .drop-zone {
        border: 1px dashed palette(aap-white, mid-light);
        text-align: center;
        padding: $spacing * 4;
    }

    .button {
        margin: $spacing 0;
        width: 40%;
        text-align: center;
        @include media($phone) {
            width: 100%;
        }
    }

    .uploaded {
        li {
            border-top: solid 1px palette(aap-white, mid-light);
            border-bottom: solid 1px palette(aap-white, x-light);
            list-style: none;
            position: relative;

            span {
                position: absolute;
                right: 0;
                top: 0;
                cursor: pointer;
            }
        }
    }
}

