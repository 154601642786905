div.facebook-share-banner {
    width: 690px;
    height: 156px;
    border-radius: 6px;
    background-color: #00a8de;
    margin: 0 auto;
    margin-top: 26px;
    overflow: hidden;
    position: relative;
    &.desktop-only {
        display: block;
        @include media($phone) {
            display: none;
        }
    }
    &.mobile-only {
        display: none;
        @include media($phone) {
            display: block;
        }
    }
    @include media($phone) {
        width: 320px;
        height: 124px;
        div.facebook-share-button.white {
            width: 167px;
            height: 38px;
            a {
                padding: 8px 10px 11px 10px;
                font-size: 11px;
                font-weight: bold;
                background: #fff;
                color: #00a8de;
                text-decoration: none;
                border-radius: 6px;
        
                span {
                    margin-left: 10px;
                    vertical-align: middle;
        
                    &:before {
                        content: " ";
                        display: inline-block;
                        background: url('/img/social/blue-facebook-icon.svg') no-repeat;
                        width: 10px;
                        height: 20px;
                        margin-right: 11px;
                        margin-bottom: 5px;
                        vertical-align: middle;
                        background-size: contain;
                    }
                }
        
                &:hover {
                    background-color: #008bb8;
                    color: #fff;
                    span {
                        &:before {
                            content: " ";
                            display: inline-block;
                            background: url('/img/social/white-facebook-icon.svg') no-repeat;
                            width: 10px;
                            height: 20px;
                            margin-right: 11px;
                            margin-bottom: 5px;
                            vertical-align: middle;
                            background-size: contain;
                        }
                    }
                }
        
                &:active {
                    background-color: #007398;
                    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.5);
                    border: solid 2px #007398;
                }
            }
        }
    }
    div.pet-photo-wrapper {
        width: 179px;
        height: 156px;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
        float: left;
        @include media($phone) {
            width: 124px;
            height: 124px;
        }
    }
    div.banner-content {
        margin-top: 18px;
        float: left;
        width: 511px;
        padding-left:30px;
        div.banner-text {
            font-size: 28px;
            font-weight: 500;
            font-family: HelveticaNeue;
            color: #ffffff;
            margin-bottom: 22px;
            @include media($phone) {
                font-size: 16px;
                margin-bottom: 10px;
            }
        }
        @include media($phone) {
            width: 156px;
            height: 48px;
            padding-left: 18px;
            margin-top: 10px;
        }
    }
}

div.facebook-share-button.blue {
    width: 338px;
    height: 58px;
    &.profile-button {
        float: left;
        display: inline-block;
        margin-top:10px;
        @include media($phone) {
            float: none;
            display:block;
            margin-top:28px;
        }
    }
    &.mobile-only {
        display: none;
        @include media($phone) {
            display: block;
            margin: 0 auto;
            margin-top: 35px;
            margin-bottom: 20px;
        }
    }
    a {
        padding:14px 55px 20px 40px;
        font-size: 20px;
        font-weight: bold;
        color: #fff;
        background: #00a8de;
        text-decoration: none;
        border-radius: 6px;

        span {
            margin-left: 20px;
            vertical-align: middle;

            &:before {
                content: " ";
                display: inline-block;
                background: url('/img/social/white-facebook-icon.svg') no-repeat;
                width: 18px;
                height: 32px;
                margin-right: 15px;
                margin-bottom: 10px;
                vertical-align: middle;
            }
        }

        &:hover {
            background-color: #008bb8;
            color: #fff;
            span {
                &:before {
                    content: " ";
                    display: inline-block;
                    background: url('/img/social/white-facebook-icon.svg') no-repeat;
                    width: 18px;
                    height: 32px;
                    margin-right: 15px;
                    margin-bottom: 10px;
                    vertical-align: middle;
                }
            }
        }

        &:active {
            background-color: #007398;
            box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.5);
            border: solid 2px #007398;
        }
    }
}

div.facebook-share-button.white {
    width: 338px;
    height: 58px;
    &.mobile-only {
        display: none;
        @include media($phone) {
            display: block;
            margin: 0 auto;
        }
    }
    a {
        padding:14px 55px 20px 40px;
        font-size: 20px;
        font-weight: bold;
        background: #fff;
        color: #00a8de;
        text-decoration: none;
        border-radius: 6px;

        span {
            margin-left: 20px;
            vertical-align: middle;

            &:before {
                content: " ";
                display: inline-block;
                background: url('/img/social/blue-facebook-icon.svg') no-repeat;
                width: 18px;
                height: 32px;
                margin-right: 15px;
                margin-bottom: 10px;
                vertical-align: middle;
            }
        }

        &:hover {
            background-color: #008bb8;
            color: #fff;
            span {
                &:before {
                    content: " ";
                    display: inline-block;
                    background: url('/img/social/white-facebook-icon.svg') no-repeat;
                    width: 18px;
                    height: 32px;
                    margin-right: 15px;
                    margin-bottom: 10px;
                    vertical-align: middle;
                }
            }
        }

        &:active {
            background-color: #007398;
            box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.5);
            border: solid 2px #007398;
        }
    }
}
