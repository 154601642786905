.payment-form-wrapper {
  .auth {
    max-width: 78%;
    padding-top: 40px;

    @include media($phablet) {
      max-width: 98%;
    }

    .payment-form-container {
      @include span-columns(6 of 12);
      float: left;

      .form-h1 {
        font-size: 3rem;
        font-weight: 700;
        color: palette(aap-mono, dark);
        margin-bottom: 0.4rem;
      }

      p {
        font-family: $font-family;
        font-weight: 200;
        font-size: 20px;
        margin: 0 0 0 0;
        line-height: 1.4;
        color: #242424;
      }

      h2 {
        margin: 0 0 0 0;
        color: palette(aap-mono, dark);
        font-family: $font-family;
        font-weight: 700;
      }

      h3 {
        font-family: $font-family;
        margin: 1rem 0 0 0;
        font-size: 20px;
        font-weight: 200;
        color: #4d4d4d;
      }

      .bold-text {
        color: #000;
        font-weight: 700;
      }

      .break-grey {
        margin-top: 3rem;
        margin-bottom: 3rem;
        color: #dfdfdf;
        opacity: 0.6;
      }

      .h2-small {
        margin-top: 1rem;
        font-size: 1.2rem;
      }

      .form-control {
        width: 52%;
        margin: 0 0 0 0;
        padding-bottom: 40px;
      }

      button {
        margin-bottom: 0;
        margin-top: 0;
      }

      .bottom-div {
        padding-bottom: 60px;
      }

      .legal-copy {
        padding-top: 10px;
        font-size: 10px;
        font-weight: 300;
        line-height: 14px;
      }

      .legal-copy-link {
        font-weight: 400;
        text-decoration: none;
        color: #00AAE3;
      }

      label {
        text-transform: none;
        font-size: 1.1rem;
      }

      @media only screen and (max-width: 1300px) {
        .form-control {
          width: 62%;
        }
      }

      @media only screen and (max-width: 1070px) {
        .form-control {
          width: 80%;
        }
      }

      @include media($phablet) {
        @include span-columns(12 of 12);

        .form-control {
          width: 100%;
        }

        h3,
        p {
          font-size: 18px;
        }

        .img-container {
          img {
            margin: 0 auto;
          }
        }
      }

    }

    .img-container {
      @include span-columns(6 of 12);
      display: -moz-inline-grid;

      img {
        display: block;
        max-width: 100%;
      }


      @include media($phablet) {
        @include span-columns(12 of 12);

        img {
          margin: 0 auto;
        }
      }
    }
  }
}
