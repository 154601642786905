fieldset {
    margin: 0;
    padding: 0;

    border: 0;
    background-color: transparent;
}

legend {
    margin-bottom: $small-spacing / 2;
    padding: 0;

    font-weight: 500;
}

label {
    display: block;

    margin-top: $spacing;
    margin-bottom: $small-spacing / 2;

    text-transform: uppercase;

    color: palette(aap-black, mid-light);

    font-weight: 700;

    &.inline {
        display: inline-block;
        font-weight: 500;
        text-transform: none;
    }

    &.label-checkbox {
        box-sizing: border-box;
        margin-bottom: $small-spacing;
        padding: $spacing / 2 $spacing / 3;

        cursor: pointer;
        transition: border-color $duration $timing;
        text-align: center;
        text-transform: none;

        color: palette(aap-white, dark);
        border: $border;
        border-radius: $border-radius;
        background-color: $background-color;
        box-shadow: $form-box-shadow;

        font-family: $font-family;
        font-weight: 500;
    }
}

input,
select,
textarea {
    box-sizing: border-box;
    margin-bottom: $small-spacing;
    padding: 1rem;

    transition: border-color $duration $timing;

    color: palette(aap-white, dark);
    border: $border;
    border-radius: $border-radius;
    background-color: $background-color;
    box-shadow: $form-box-shadow;

    font-family: $font-family;
    font-size: 1rem;

    &:hover {
        border-color: shade($border-color, 20%);
    }

    &:focus {
        border-color: palette(aap-blue, dark);
        outline: none;
        box-shadow: $form-box-shadow-focus;
    }

    &:disabled {
        cursor: not-allowed;

        background-color: shade($background-color, 5%);
        &:hover {
            border: $border;
        }
    }

    &.error {
        border-color: $error-color;
    }

    &.valid {
        border-color: palette(aap-green, light);
    }

    &.checkbox {
        display: none;

        &:checked {
            & + .label-checkbox {
                color: palette(aap-white, x-light);
                border-color: palette(aap-blue, dark);
                background: palette(aap-blue, dark);
            }
        }
    }
}

select {
    width: 100%;
    background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAIAAAACACAYAAADDPmHLAAAABHNCSVQICAgIfAhkiAAAAAlwSFlzAAALEwAACxMBAJqcGAAAAOtJREFUeJzt0bFNQwEMRdGXsZiLFsgbgMWYCgoamkhRlB+L+BzJve2bAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAABwuNP0Ahe8JHmdXuKOvpN8JvmaXuQ/aX4f9wzzdt/X7NHMxxN/WDMfUfxhzXxM8Yc181HFH9bMxxV/WDMf+dK8H3c2fzXzscUf1sxHF39YI/56jfjrNeKv14i/XnN8/I9HHcNtGvHXa8RfrxF/vUb89Rrx12vEX6+5Pv55ZkWO1oi/XiP+eo346zXir9eIDwAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAPNgPwESkVWmaAb8AAAAASUVORK5CYII=');
    background-repeat: no-repeat;
    background-position: center right;
    background-size: contain;

    @include appearance(none);
}

textarea {
    width: 100%;
    height: 8rem;

    resize: vertical;
}

[type='search'] {
    @include appearance(none);
}

[type='checkbox'],
[type='radio'] {
    display: inline;

    margin-right: $small-spacing / 2;
}

[type='text'],
[type='password'],
[type='email'],
[type='number'],
[type='tel'] {
    width: 100%;
}

[type='file'] {
    width: 100%;
    margin-bottom: $small-spacing;
}
