html {
    font-size: 15px;
    line-height: 1.6;
    font-family: $helvetica;
}

h1,
.h1 {
    margin: 0 0 1rem;
    color: palette(aap-blue, dark);
    font-size: 2.5rem;
    line-height: 1.3;

    @include font('Museo', 500);
//    letter-spacing: fake-kern(-10);

    &.extra-top-margin {
        margin-top: 1.5em;
    }
}

h2,
.h2 {
    margin: 1rem 0 2rem;
    color: palette(aap-blue, dark);
    font-size: 1.5rem;

    @include font('Museo', 500);
//    letter-spacing: fake-kern(-10);
}

h3,
.h3 {
    margin: 1rem 0 2rem;
    color: palette(aap-blue, dark);
    font-size: 1.2rem;

    @include font('Museo', 300);
    letter-spacing: fake-kern(-10);
}

h4,
.h4 {
    margin: 1rem 0 2rem;
    color: palette(aap-white, dark);
    font-size: 1rem;
}

h5,
.h5 {
    margin: 1rem 0 2rem;
    color: palette(aap-white, dark);
    font-size: 1rem;
    font-weight: 700;
}

h6,
.h6,
p {
    margin: 1.8rem 0;
    color: palette(aap-white, dark);
    font-size: 1rem;
}
p.default {
  font-family: Helvetica Neue;
  font-style: normal;
  font-weight: normal;
  line-height: 32px;
  font-size: 20px;
  letter-spacing: -0.25px;
}

.small {
    font-size: .8rem;
    font-weight: 200;
    line-height: 24px;
    font-size: 16px;
}

.tiny {
    font-size: .6rem;
}
.extra-top-margin {
    margin-top: 1.5em;
}
.centered {
    text-align: center;
}
a {
    color: palette(aap-white, dark);

    &:hover {
        text-decoration: none;
    }
}
