// @include font('Museo', 500); //500
// @include font('Museo', 700); //700
// @include font('Museo Sans'); //300
// @include font('Museo Sans', 500); //500
// @include font('Museo Sans', 700); //700
@mixin font($font: 'Museo', $weight: 300) {
    font-family: $font, $helvetica;
    font-weight: $weight;
    font-style: normal;
}
//*
// * Fetch from Color Palette
// * -----------------------------------------------------------------------------
// * In short, the post advocates a mapped palette of colors and tones using a
// * set schema, and then using this function to return the colors.
// *
// *
// * @param   string      $color          Colour to be used from map.
// * @param   string      $tone           Default tone to be used.
// * @param   map         $palette        Palette of colours.
// * @return  string                      Colour chen from map.
// *
// * @link http://erskinedesign.com/blog/friendlier-colour-names-sass-maps/
//
@function palette($color, $tone: 'base', $palette: $default-colors) {
    @return map-get(map-get($palette, $color), $tone);
}
//background rgba mixin
@mixin background-opacity($color, $opacity: .3) {
    background: $color;
    background: rgba($color, $opacity);
}
@mixin line-clamp($maxLines:2, $fgCol:#000, $bgCol:#fff, $lineHeight:$line-height) {
    position: relative;
    overflow: hidden;
    .webkit-line-clamp & {
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: $maxLines;
    }
    html:not(.webkit-line-clamp) & {
        max-height: $maxLines * $lineHeight * 1em;
        &:before {
            top: ($maxLines - 1) * $lineHeight * 1em;
            @extend %line-clamp-ellipsis;
            color: $fgCol;
        }
        &:after {
            @extend %line-clamp-filler;
            background: $bgCol;
        }
    }
}
%line-clamp-ellipsis {
    min-width: 30px;
    content: "\2026";
    position: absolute;
    right: 0;
    background: palette(aap-white, x-light);
}
%line-clamp-filler {
    $whitespace: '';
    @for $i from 1 through 1024 {
        $whitespace: $whitespace + '  ';
    }
    content: $whitespace;
    display: inline;
    letter-spacing: 1em;
    position: relative;
    z-index: 1;
    font-size: 75%;
}
;
$circle-color: white;
//Media Play Button
@mixin play-button ($fontsize, $circlesize, $circle-transparency, $arrow-transparency) {
    position: relative;
    &:after {
        content: '▶';
        display: block;
        width: $circlesize;
        height: $circlesize;
        border-radius: 100%;
        position: absolute;
        left: calc(50% - #{$circlesize / 2});
        top: calc(50% - #{$circlesize / 2});
        background-color: transparentize($circle-color, $circle-transparency);
        color: transparentize(white, $arrow-transparency);
        font-size: $fontsize;
        line-height: $circlesize * 1.1;
        text-align: center;
        vertical-align: bottom;
    }
}
$base-spacing: 1.5em !default;
$alert-color: #fff6bf !default;
$error-color: #fbe3e4 !default;
$notice-color: #e5edf8 !default;
$success-color: #e6efc2 !default;
@mixin flash($color) {
    background-color: $color;
    color: darken($color, 60%);
    display: block;
    margin-bottom: $base-spacing / 2;
    padding: $base-spacing / 2;
    text-align: center;
    a {
        color: darken($color, 70%);
        text-decoration: none;
        float: right;
        font-size: 25px;
        line-height: 1;
        &:focus,
        &:hover {
            color: darken($color, 90%);
        }
    }
}


@mixin aap-first {
	display: block;


  tbody {
    border: none;
  }
		tbody, th, td, tr, tfoot {
			display: block;
		}

		thead {
			display: none;
		}
		tr {
      float: left;
      width: 100%;
      margin: 0 0 1em;
       border: 1px solid palette(aap-white, dark);
			box-shadow: 0px 2px 5px 0px palette(aap-white, dark);
			border-radius: 6px;
			border-top: 5px solid palette(aap-blue, dark);

			td {
				padding: .5em .5em .5em 50%;
				float:left;
       		width: 100%;
				&:before {
					width: 100%;
					display: block;
					float: left;
					padding: .5em .5em .5em 0;
					clear: both;
					margin: -.5em 0 0 -100%;
					font-size: 1.125em;

				}
			}
		}

		tr:nth-of-type(even) {
			//background: $gray-color;
			td {

				&:before {

				}
			}
		}

}
$base-spacing: 1.5em !default;
$alert-color: #fff6bf !default;
$error-color: #fbe3e4 !default;
$notice-color: #e5edf8 !default;
$success-color: #e6efc2 !default;
@mixin flash($color) {
    background-color: $color;
    color: darken($color, 60%);
    display: block;
    margin-bottom: $base-spacing / 2;
    padding: $base-spacing / 2;
    text-align: center;
    a {
        color: darken($color, 70%);
        text-decoration: underline;
        &:focus,
        &:hover {
            color: darken($color, 90%);
        }
    }
}
