button,
[type='button'],
[type='submit'],
.button {
    display: inline-block;

    padding: $small-spacing;

    cursor: pointer;
    user-select: none;
    transition: background-color $duration $timing;
    vertical-align: middle;
    white-space: nowrap;
    text-decoration: none;

    color: palette(aap-black, x-dark);
    border: 0;
    border-radius: $border-radius;
    background-color: palette(aap-white, x-light);

    font-size: 1.5rem;
    font-weight: 500;
    line-height: 1;

    appearance: none;
    @include font('Museo', 500);

    &:hover,
    &:focus {
        background-color: shade(palette(aap-white, x-light), 20%);
    }

    &:disabled {
        cursor: not-allowed;

        opacity: .5;
        &:hover {
            background-color: palette(aap-white, x-light);
        }
    }

    &.red {
        color: palette(aap-white, x-light);
        background-color: palette(aap-red, mid-light);
        &:hover,
        &:focus {
            background-color: shade(palette(aap-red, mid-light), 20%);
        }
    }

    &.blue {
        color: palette(aap-white, x-light);
        background-color: palette(aap-blue, dark);
        &:hover,
        &:focus {
            background-color: shade(palette(aap-blue, dark), 20%);
        }
    }

    &.orange {
        color: palette(aap-white, x-light);
        background-color: palette(aap-orange, dark);
        &:hover,
        &:focus {
            background-color: shade(palette(aap-orange, dark), 20%);
        }
    }

    &.green {
        color: palette(aap-white, x-light);
        background-color: palette(aap-green, dark);
        &:hover,
        &:focus {
            background-color: shade(palette(aap-green, dark), 20%);
        }
    }

    &.inverted {
        color: palette(aap-black, mid-dark);
        background-color: transparent;
        border: $border;
        &:hover,
        &:focus {
            color: shade(palette(aap-black, mid-dark), 20%);
        }
    }

    &.full {
        width: 100%;
    }
}
