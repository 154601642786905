.contract {
    @include outer-container(1200px);
    padding: 0 $spacing;
    ol {
        li {
            color: palette(aap-black, mid-dark);
            margin: $spacing 0;
        }
    }

    .field-holder {
        @include outer-container(100%);
        label {
            @include span-columns(2);
        }
        [type=text],
        select {
            @include span-columns(10);
        }

        &.location {
            .city {
                @include span-columns(5);
            }
            .zip {
                @include span-columns(3);
            }
        }

        @include media($phone) {
            label,
            [type=text] {
                @include span-columns(12);
            }
            &.location {
                .city,
                .zip {
                    @include span-columns(12);
                }
            }
        }
    }

    strong {
        color: palette(aap-black, dark);
    }
    select {
        display: inline-block;
    }
    .form-control {

        [type=text] {
            border-top: 0;
            border-left: 0;
            border-right: 0;
            box-shadow: none;
            border-radius: 0;
            display: inline-block;
        }
        label {
            margin-top: 0;
            display: inline-block;
        }
        .more {
            &.closed {
                display:none;
           }
        }
    }

    .owner {
        background-color: palette(aap-white, light);
        padding: $spacing;
        margin-top: $spacing;
        margin-bottom: $spacing;
    }

    .transfer {
        padding-bottom: $spacing;
    }

    .submission {
        margin: $spacing 0;
    }

    .capitalize {
        text-transform: capitalize;
    }
    .bordered {
        border: solid 1px palette(aap-white, mid-light);
        background: palette(aap-white, light);
        border-radius: $border-radius;
        padding: 2rem;
    }

    .here-to-help {
        text-align: left;
    }
}

.adopted {
    @include outer-container(900px)
    text-align: center;
    padding-bottom: $spacing * 4;
    .button {
        margin-left: $spacing;
        margin-right: $spacing;
        width: 120px;
        &.large {
            width: auto;
        }
    }
}
