div#shelter_rescue_search_form {
    padding: 4%;
    overflow-y: scroll;

    div.alpha-filters {
        font-family: "Helvetica Neue","Helvetica","Roboto","Arial",sans-serif;
        button.alpha_filter_button {
            &.selected-filter {
                background: palette(aap-blue, dark);
                color: palette(aap-white, x-light);
            }
            border: 1px solid palette(aap-white, border-grey);
        }
        @include media($phone) {
            display: none;
        }
    }

    div.alpha-filters-mobile {
        display: none;
        @include media($phone) {
            display: block;
        }
    }

    div.shelter_rescue_pagination {
        max-width: 100%;
        width: 100%;
        div.shelter_rescue_page_buttons {
            float: right;
        }
        @include media($phone) {
            display: none;
        }
    }

    div.shelter_rescue_pagination_bottom {
        display: none;
        @include media($phone) {
            display: block;
            text-align: center;
        }
    }
    
    input.shelter_rescue_search_input {
        width: 99%;
        padding-right: 6%;
        @include media($phone) {
            padding-right: 20%;
        }
    }


    button.shelter_rescue_search_submit {
        width: 56px;
        height: 56px;
        margin-left: -60px;
        padding: 2px;
    }

    div.shelter_rescue_pagination_details_mobile {
        display: none;
        @include media($phone) {
            display: block;
            text-align: center;
        }
    }

    div.shelter_rescue_select {
        button.shelter_rescue_select_button {
            width: 100%;
        }
    }

    div.shelter_rescue_total_shelters {
        width: 100%;
        max-width: 100%;
        border-top: 1px solid palette(aap-white, border-grey);
        font-weight: 600;
    }

    div.shelter_rescue_is_mobile {
        display: none;
        @include media($phone) {
            display: block;
        }
    }

    div.shelter_rescue_results {
        max-width: 100%;
        width: 100%;
        div.shelter_rescue_single {
            width: 50%;
            float:left;
            border-top: 1px solid palette(aap-white, border-grey);
            padding-top: 15px;
            padding-bottom: 15px;
            
            span.shelter_rescue_shelter_name {
                display: inline-block;
            }
            span.shelter_rescue_shelter_city {
                display: block;
                margin-left: 17px;
            }
            @include media($phone) {
                width: 100%;
                white-space: nowrap;
            }

            &:nth-last-child(1):nth-child(odd) {
                width: 100%;
            }
        }
    }
}
