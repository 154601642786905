.nav {
    .nav-container {
        @include outer-container(1170px);
        position: relative;

        @include media($tablet-plus) {
            @include outer-container(977px);
        }
        @include media($tablet) {
            @include outer-container(750px);
        }
        @include media($phone) {
            @include outer-container(100%);
            margin: 0 $spacing;
        }

        .notification {
            height: 25px;
            width: 25px;
            -moz-border-radius: 36px;
            border-radius: 36px;
            padding: 3px;
            display: inline-block;
            color: palette(aap-white, x-light);
            text-shadow: 0px 1px 1px palette(aap-black, mid-light);
            font-weight: bold;
            font-family: helvetica;
            text-align: center;
            line-height: 18px;
            font-size: 12px;
            top: 4px;
            right: -5px;
            position: absolute;
            z-index: 10;
        }

        p.login {
            float: right;
        }

        .yellow{
            background-color: palette(aap-orange, light);
            border: 1px solid palette(aap-orange, dark);
        }
    }
}
